.admin-container {
    display: inline-flex;
    justify-content: space-between;
    gap: 50px;
    height: 100%;
    width: 100%;
    padding: 3%;

    .back-action {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 5rem;

        .back-btn {
            background-color: #000;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 10px 20px;
        }
    }

    .options-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;

        .select-user-container {
            width: 100%;
            margin-bottom: 40px;
        }

        .menu {
            width: 100%;
            display: inline-flex;
            flex-direction: row;

            .support-actions-container {
                height: 50%;
                width: 100%;
                display: inline-flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: center;
                align-items: center;

                .action-box {
                    background-color: #000;
                    padding: 20px;
                    height: 120px;
                    width: 150px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    cursor: pointer;
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: scale(1.1);
                    }

                    .action-icon {
                        font-size: 28px;
                        color: #fff;
                    }

                    .action-label {
                        font-weight: 500;
                        color: #fff;
                        line-height: 18px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .details-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        height: 100%;

        .details-header {
            display: inline-flex;
            align-items: center;
            gap: 20px;
            justify-content: flex-start;
            width: 100%;
            height: 20%;

            .profile {
                border: 5px solid #1a1a1a;
                padding: 30px 40px;
                border-radius: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                svg {
                    height: 70px;
                }
            }

            .details-info {
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: flex-start;
                align-items: flex-start;

                .user-info {
                    display: inline-flex;
                    align-items: center;
                    justify-content: space-between;

                    svg {
                        height: 20px;
                        margin-right: 10px;
                    }
                }

            }
        }

        .details-address {

            .details-title {
                font-size: 20px;
                font-weight: 300;
            }

            .details-content {
                line-height: 20px;
                font-size: 18px;
                font-weight: 300;
            }
        }

        .details-actions {
            display: inline-flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;

            .action-box {
                border-radius: 10px;
                height: 110px;
                width: 120px;
                box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.1);
                }

                .action-icon {
                    font-size: 28px;
                    color: #fff;
                }

                .action-label {
                    font-weight: 500;
                    color: #fff;
                    line-height: 18px;
                    text-align: center;
                }
            }

            .box-danger {
                background-color: #fff;
                border: 2px solid red;

                .action-icon, .action-label {
                    color: red;
                }
            }

            .box-success {
                background-color: #fff;
                border: 2px solid green;

                .action-icon, .action-label {
                    color: green;
                }
            }

            .box-info {
                background-color: #fff;
                border: 2px solid #1A1A1A;

                .action-icon, .action-label {
                    color: #1A1A1A;
                }
            }
        }

    }


    .pagination-container {
        height: 5%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        span {
            font-size: 14px;
            font-weight: 500;
        }

        button {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }
    }
}