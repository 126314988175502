$desktop: 900px;
$laptop: 1700px;
$mobile: 505px;

.table-orders-container {
    max-height: 80%;
    width: 100%;

    .MuiPaper-root {
        height: 80%;

        table {
            max-height: 100%;

            thead {
                height: 10%;
            }

            tbody {
                max-height: 90%;

            }
        }
    }
}

.collapse-order {
    display: inline-flex;
    align-items: center;
    justify-items: center;
    width: 100%;
    margin-top: 20px !important;
}

.collapse-follow-up {
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    .status-box {
        width: 24%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .status-circle-box {
            height: 70%;
            width: 100%;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;

            .circle-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 20%;

                .circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                    width: 100px;
                    border-radius: 50px;
                    background-color: #ccc;

                    svg {
                        height: 20px;
                    }
                }
            }



            .line {
                height: 2px;
                width: 80%;
                background-color: #ccc;
            }
        }

        .status-info-text {
            width: 20%;
            margin-top: 10px;

            .status-name {
                font-weight: 600;
                font-size: 12px;
                text-align: center;
            }

            .status-time {
                font-weight: 500;
                font-size: 10px;
                text-align: center;
            }
        }

    }

    .status-box-active {
        .status-circle-box {
            .circle-container {
                .circle {
                    background-color: #ccc;
                    animation: fill-left-to-right 0.5s forwards;
                }
            }
            .line {
                background-color: #ccc;
                animation: fill-left-to-right 0.5s forwards;
            }
        }
    }

    .status-box-active:nth-child(1) {

        .circle-container .circle,
        .line {
            animation-delay: 0s;
        }
    }

    .status-box-active:nth-child(2) {

        .circle-container .circle,
        .line {
            animation-delay: 0.5s;
        }
    }

    .status-box-active:nth-child(3) {

        .circle-container .circle,
        .line {
            animation-delay: 1s;
        }
    }

    .status-box-active:nth-child(4) {

        .circle-container .circle,
        .line {
            animation-delay: 1.5s;
        }
    }

    .status-box-active:nth-child(5) {

        .circle-container .circle,
        .line {
            animation-delay: 2s;
        }
    }
}

@keyframes fill-left-to-right {
    0% {
        background-color: #ccc;
        background-size: 0 100%;
    }
    100% {
        background-color: #168821ff;
        background-size: 100% 100%;
    }
}

.collapse-order .collapse-order-box {
    width: 33%;
    height: 250px;
    margin-inline: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
}

.collapse-order .collapse-order-box h4 {
    margin: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
}

.collapse-order .collapse-order-box p {
    margin: 5px 10px;
}

button {
    cursor: pointer;
}

.pagination-buttons button {
    margin-top: 10px;
    margin-right: 10px;
}

.btn {
    display: inline-block;
    font-weight: 400;
    width: 50px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.page-btn {
    color: black !important;
    background-color: #f2f2f2 !important;
    border-collapse: collapse;
    border: 1px solid #bbbbbb !important;
    width: 50px !important;
}

.btn-danger {
    color: #fff !important;
    background-color: #dc3545 !important;
    border-color: #dc3545 !important;
    margin-left: 10px !important;
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-warning {
    color: #313131;
    background-color: #ffc107;
    border-color: #ffc107;
    margin-left: 10px;
}

.btn:hover {
    color: black;
    background-color: #f5f5f5;
}

.page-title {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .ifood-type {
        background-color: red;
    }

    .anota-ai-type {
        background-color: #0098FC;
    }
    
    .origin-type {
        padding: 8px 15px;
        border-radius: 5px;
        display: inline-flex;
        justify-content: space-around;
        align-items: center;

        .origin-type-logo {
            height: 20px;
            margin-right: 5px;
        }
    }

    

    th,
    td {
        padding: 7px 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        color: #fff !important;
        font-weight: 500;
        background-color: #000;
    }

    tr:hover {
        background-color: #f5f5f5;
    }
    
    tr {
        background-color: #fff;
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.container-one,
.container-two {
    flex: 1;
}

.container-one {
    margin-right: 40px;
}

.order-details-modal {
    background: #fff;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 800px;
    width: 100%;
    text-align: left;
}

.select-store-follow-up {
    margin-bottom: 10px;
}

.order-details-modal h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
}

.order-details-modal p {
    margin-bottom: 10px;
}

.order-details-modal button {
    padding: 10px 15px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
}

/* continer-one */
.order-details-title {
    font-size: 20px;
    text-align: center;
}

.delivery-man-container h4 {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    margin: 20px;
}

.delivery-man-info {
    margin-top: 40px;
}

.follow-up-info-container {
    font-size: 10px;
    display: flex;
}

.container-situation {
    margin-inline: 10px;
    min-width: 70px;
    text-align: center;
}

.situation-circle {
    color: white;
    width: 50px;
    height: 50px;
    margin: 5px auto 5px 10px;
    background-color: black;
    /* Cor de fundo da div */
    transition: background-color 5s;
    border-radius: 50%;
}

.line-one {
    width: 40px;
    height: 2px;
    background-color: black;
    position: absolute;
    margin-left: 2.75%;
    margin-top: 1.9%;
    z-index: 999;
}

.line-two {
    width: 40px;
    height: 2px;
    background-color: black;
    position: absolute;
    margin-left: 6.26%;
    margin-top: 1.9%;
    z-index: 999;
}

.line-tree {
    width: 40px;
    height: 2px;
    background-color: black;
    position: absolute;
    margin-left: 9.78%;
    margin-top: 1.9%;
    z-index: 999;
}

.line-green-one {
    animation: colorir 1s forwards;
}

.line-green-two {
    animation: colorir 1s forwards;
}

.line-green-tree {
    animation: colorir 1s forwards;
}

.circle-green-one {
    animation: colorir 1s forwards;
}

.circle-green-two {
    animation: colorir 2s forwards;
}

.circle-green-tree {
    animation: colorir 3s forwards;
}

.circle-green-four {
    animation: colorir 4s forwards;
}

.circle-red {
    animation: vermelho 1s forwards
}

.hide-circle {
    display: none;
}

@keyframes colorir {
    from {
        background-color: black;
    }

    to {
        background-color: green;
    }
}

@keyframes vermelho {
    from {
        background-color: black;
    }

    to {
        background-color: #dc3545;
    }
}

/* container-two */

.details-title {
    margin-top: 20px;
}

.details-text {
    font-weight: 500;
}

.close-modal-btn {
    margin: 8% 0 0 30%;
    width: 150px;
    height: 50px;
}

.refresh-button {
    float: right;
    margin: 10px 0px;
}

@media only screen and (min-width: 1681px) and (max-width: 1920px) {
    .line-one {
        margin-top: 2.5%;
        margin-left: 3.66%;
    }

    .line-two {
        margin-left: 8.38%;
        margin-top: 2.5%;
    }

    .line-tree {
        margin-top: 2.5%;
        margin-left: 13.12%;
    }
}

@media only screen and (min-width: 1100px) and (max-width: $laptop) {
    .line-one {
        margin-top: 3.1%;
        margin-left: 4.55%;
    }

    .line-two {
        margin-left: 10.45%;
        margin-top: 3.1%;
    }

    .line-tree {
        margin-top: 3.1%;
        margin-left: 16.3%;
    }
}

@media only screen and (min-width: 506px) and (max-width: 1024px) {
    .line-one {
        margin-top: 4.5%;
        margin-left: 6.8%;
    }

    .line-two {
        margin-top: 4.5%;
        margin-left: 15.6%;
    }

    .line-tree {
        margin-top: 4.5%;
        margin-left: 24.4%;
    }
}

@media screen and (max-width: $mobile) {
    .order-details-modal {
        display: block;
        font-size: 10px;
        margin-top: 15rem;
        padding-left: 25px;
    }

    .follow-up-info-container .container-situation {
        min-width: 25%;
    }

    .container-two {
        margin-top: 5rem;
    }

    .line-one {
        margin-top: 12%;
        margin-left: 17.7%;
    }

    .line-two {
        margin-top: 12%;
        margin-left: 40.4%;
    }

    .line-tree {
        margin-top: 12%;
        margin-left: 63.3%;
    }
}