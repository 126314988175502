.action-buttons-container {
    position: absolute;
    top: 5vh;
    right: 12vh;
}

.action-buttons-container .config-icon {
    height: 20px;
    color: #000;
    cursor: pointer;
}