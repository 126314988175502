.integrations-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .integration-header {
        margin-bottom: 10px;
        
        button {
            padding: 5px 15px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            border-radius: 5px;
            font-size: 14px;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #000;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .integrations-search {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        margin: 10px 0;
    }

    .user-integrations {
        background-color: red;
        height: 80%;


        .integration-item {
            height: 50px;

            .type-td {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .ifood-type {
                    background-color: red;
                }

                .anota-ai-type {
                    background-color: #0098FC;
                }

                .type {
                    padding: 5px 10px;
                    border-radius: 5px;
                    width: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .type-logo {
                        height: 15px;
                    }
                }
            }

            .token-td {
                width: 40%;
            }

            .actions-buttons {
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: space-around;

                .approved {
                    background-color: #50A773;
                    border: none;
                    border-radius: 5px;
                    color: #fff;
                    padding: 5px 10px;
                    transition: transform 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                .denied {
                    background-color: #DBA827;
                    border: none;
                    border-radius: 5px;
                    color: #fff;
                    padding: 5px 10px;
                    transition: transform 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.05);
                    }
                }

                .destroy {
                    background-color: #EA1D2C;
                    border: none;
                    border-radius: 5px;
                    color: #fff;
                    padding: 5px 10px;
                    transition: transform 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}

.loading-integration {
    align-items: center;
    justify-content: center;
}