.shopkeeper-stores-container {
    height: 100%;
    width: 100%;

    .header-stores {
        height: 5%;
        width: 95%;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;

        button {
            padding: 5px 15px;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            border: none;
            border-radius: 5px;
            font-size: 14px;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #000;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .stores {
        height: 95%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        padding-top: 20px;
        
        .store-box {
            width: 90%;
            min-height: 170px;
            padding-left: 50px;
            padding-right: 10px;
            border-radius: 10px;
            background-color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            transition: transform 0.3s ease;
            gap: 20px;

            &:hover {
                transform: scale(1.05);
            }

            .store-profile {
                background-color: #EEEEEE;
                padding: 40px;
                border-radius: 100%;

                svg {
                    height: 50px;
                }
            }

            .store-details {
                height: 120px;
                width: 90%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;

                .store-info {
                    display: inline-flex;
                    gap: 10px;
                    font-weight: 500;
                }
            }

            .store-actions {
                width: 10%;
                height: 90%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                
                svg {
                    color: #000;
                }
            }
        }
    }
}