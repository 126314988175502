.my-stores-container {
    height: 100%;
    width: 100%;

    .fees-container {
        height: 15%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        .fee-input {
            width: 20%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
        }

        .fee-input-range {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 5px;

            label {
                display: inline-flex;
                align-items: center;
                gap: 10px;
            }
        }

        .fee-submit {
            width: 20%;

            button {
                width: 100%;
                text-align: center;
                padding: 5px 15px;
                border: none;
                height: 40px;
                border-radius: 5px;
                font-size: 14px;
                color: #fff;
                transition: transform 0.3s ease;
                background-color: #000;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    .my-stores-navbar {
        height: 5%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        .nav-button {
            width: 50%;
            height: 30px;
            border: none;
            border-bottom: 2px solid #ccc;
            color: #000;
            opacity: 0.5;
        }

        .nav-active-button {
            border-bottom: 2px solid #000;
            color: #000;
            opacity: 1;
        }
    }

    .my-stores-content {
        height: 80%;
        width: 100%;

        .licensed-nearby-stores {
            height: 100%;
            width: 100%;
            padding-top: 20px;
            display: inline-flex;
            gap: 10px;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;

            .store-box {
                padding: 15px 20px;
                width: 350px;
                background-color: #fff;
                border-radius: 5px;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                .store-img {
                    height: 100%;
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .profile {
                        background-color: #fff;
                        border: 1px solid #000;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 80px;
                        width: 80px;

                        svg {
                            height: 30px;
                        }
                    }
                }

                .store-details {
                    padding-left: 10px;
                    height: 100%;
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    justify-content: center;
                    align-items: flex-start;

                    .store-name {
                        font-size: 16px;
                        width: 100%;
                        display: inline-flex;
                        justify-content: space-between;

                        svg {
                            cursor: pointer;
                            height: 18px;
                        }
                    }

                    .store-cell {
                        svg {
                            margin-right: 5px;
                            height: 14px;
                        }

                        font-size: 14px;
                    }

                    .store-address {
                        display: inline-flex;
                        align-items: center;
                        width: 100%;

                        svg {
                            margin-right: 5px;
                            height: 14px;
                        }

                        span {
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }

                    .store-actions {
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;

                        .status-connection {
                            border: none;
                            border-radius: 5px;
                            color: #fff;
                            padding: 5px 10px;
                            transition: transform 0.3s ease;
                            width: 100%;
                            text-align: center;
                        }

                        button {
                            border: none;
                            border-radius: 5px;
                            color: #fff;
                            padding: 5px 10px;
                            transition: transform 0.3s ease;
                            cursor: pointer;

                            &:hover {
                                transform: scale(1.05);
                            }
                        }

                        .connect-store {
                            width: 100%;
                            background-color: #50A773;
                        }
                    }
                }
            }
        }

    }
}

.loading-oval-content {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
}