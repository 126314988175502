.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background-color: white;
    padding: 40px 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
}

.modal h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}

.modal button {
    padding: 10px 20px;
    background-color: #000;
    color: white;
    font-size: 14px;
    width: 100%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal button.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}