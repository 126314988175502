@import "./../../../assets/css/mixin.scss";

.field {
    &__input:invalid:not(:placeholder-shown) {
        border: 1px solid red;
    }

    &__icon {
        background: url(./../../../assets/icon/error.svg) no-repeat center / contain;
        display: none;
        height: 1.2rem;
        position: absolute;
        right: 1rem;
        top: 2rem;
        transform: translateY(-50%);
        width: 1.2rem;
        opacity: 0;
    }

    &__input:invalid ~ &__icon {
        animation: show-icon-error 500ms ease-in-out forwards;
    }
   
    &__input:valid ~ &__icon {
        animation: show-icon-error 500ms ease-in-out reverse forwards;
    }

    &__input:not(:placeholder-shown):invalid ~ .field__icon {
        display: block;
    }

    &__container-erro {
        overflow: hidden;
        transition: height 1s ease-in-out;
        animation: hide-error 500ms ease-in-out forwards;

        &--show {
            animation: show-error 500ms ease-in-out forwards;
        }
    }

    &__mensagem-erro{
        font-size: 12px;
        margin-top: .5rem;
        text-indent: .5rem;
        font-family: var(--main-font);

    }
}

$container-error-size: 1.5rem;
@include animation-height(0, $container-error-size, show-error);
@include animation-height($container-error-size, 0, hide-error);

@keyframes show-icon-error {
    to {
        opacity: 1;
    }
}
