.order-management-container {
    align-items: center;
    justify-content: flex-start;
    padding: 2%;

    .filter-buttons-container {
        display: inline-flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-start;
        margin-bottom: 4%;
        height: 10%;
        width: 100%;

        .filter-box {
            border: 2px solid black;
            background-color: green;
            height: 100px;
            width: 15%;
            padding-inline: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }

            .filter-box-count {
                font-size: 24px;
                margin-bottom: 5px;
                font-weight: 600;
            }

            .filter-box-text {
                text-align: center;
                counter-reset: #453F51;
                font-size: 14px;
                font-weight: 300;
            }
        }
    }

    .actions-buttons {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        justify-content: space-between;
        width: 100%;
        height: 5%;

        .show-filters {
            background-color: #0d6efd;
            padding: 5px 15px;
            border-radius: 5px;
            width: 10px;
            height: 30px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease;

            svg {
                color: #fff;
            }

            &:hover {
                transform: scale(1.1);
            }
        }

        .group-buttons {
            button {
                height: 30px;
                padding: 5px 15px;
                margin-bottom: 10px;
                border: none;
                border-radius: 5px;
                color: #fff;
                transition: transform 0.3s ease;
    
                &:hover {
                    transform: scale(1.1);
                }
            }
    
            .btn-primary {
                background-color: #0d6efd;
            }
    
            .btn-danger {
                background-color: #dc3545;
            }
        }

    }

    .management-list {
        padding: 15px;
        display: inline-flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        height: 80%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        align-items: center;

        .no-orders {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
                font-size: 30px;
                margin-bottom: 10px;
            }

            p {
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 500;
            }
        }
    }

    .pagination-container {
        height: 5%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        span {
            font-size: 14px;
            font-weight: 500;
        }

        button {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }
    }
}