.manage-fees-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .manage-fees-header {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 95%;
        margin-bottom: 3%;

        .back-page {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }

        .manage-fees-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .manage-fees-content {
        height: 100%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        .manage-fees-inputs {
            height: 100%;
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .fee-input {
                margin-bottom: 10px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                svg {
                    height: 20px;
                }
            }

            .manage-fees-actions {
                height: 50%;
                width: 100%;
                display: inline-flex;
                gap: 20px;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
        
                button {
                    background-color: #fff;
                    border-radius: 10px;
                    height: 100px;
                    width: 110px;
                    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    cursor: pointer;
                    transition: transform 0.3s ease;
        
                    svg {
                        height: 20px;
                    }
        
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
        
    
        .manage-fees-list {
            height: 100%;
            width: 60%;

            h4 {
                font-size: 20px;
                text-align: center;
                margin-bottom: 10px;
                font-weight: 500;
            }
    
            .td-actions {
                text-align: center;
            }
    
            .fee-remove-btn {
                background-color: #EA1D2C;
                border: none;
                border-radius: 5px;
                color: #fff;
                padding: 5px 10px;
                transition: transform 0.3s ease;
                cursor: pointer;
    
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}
