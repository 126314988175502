.form-request {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 90%;
    width: 100%;
    padding: 5%;
    background-color: #fff;
    border-radius: 10px;

    .address-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 55%;

        .store-address {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            font-size: 12px;
            font-weight: 500;
            height: 10%;
            width: 100%;

            .select-store-from-request {
                width: 70%;
            }

            span {
                text-align: right;
                font-size: 12px;
                font-weight: 300;
                line-height: 20px;
            }
        }

        .google-map-container {
            height: 80%;
            width: 100%;

            .search-box .field {
                opacity: 1;

                .field__label {
                    font-size: 12px;
                }
            }

            .map {
                height: 80%;
            }
        }

        .address-form__container-shipping {
            height: 10%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .shipping  {
                height: 100%;
                width: 30%;

                label {
                    input {
                        border: 1px solid #ccc;
                        border-radius: 5px;
                        width: 100%;
                        height: 30px;
                        text-align: center;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    .shipping__label {
                        margin-top: 5px;
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
        }
    }

    .resume {
        height: 100%;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        .personal {
            width: 100%;

            .address-form__legend {
                font-size: 14px;
                font-weight: 500;
                margin: 10px;
            }

            .field__label {
                font-size: 12px;
            }
            
            .text-area__input:focus-visible {
                outline: none;
            }
        }

        .payment {
            width: 100%;

            select {
                font-size: 12px;
                border: 1px solid #ccc;
            }

            .address-form__legend {
                font-size: 14px;
                font-weight: 500;
                margin: 10px;
            }

            .field__label {
                font-size: 12px;
            }

        }

        .submit-request-form {
            background-color: #000;
            border: none;
            border-radius: 5px;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
            height: 10%;
            width: 100%;
        }
    }
}