.licensed-connection-container {
    height: 100%;
    width: 100%;

    .my-licensed-connection-active {
        height: 40%;
        width: 100%;

        .not-found-connection {
            height: 80%;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            svg {
                width: 10%;
                height: 40px;
            }

            span {
                width: 90%;
                font-size: 16px;
                font-weight: 300;
            }
        }

        .licensed-info-container {
            width: 100%;
            height: 90%;
            display: inline-flex;
            gap: 20px;
            align-items: center;
            justify-content: flex-start;

            .licensed-info-profile {
                background-color: #fff;
                border: 1px solid #000;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 150px;
                width: 150px;

                svg {
                    height: 70px;
                }
            }

            .licensed-info-details {
                height: 70%;
                width: 70%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;

                .licensed-info {
                    svg {
                        margin-right: 10px;
                        height: 20px;
                    }

                    font-size: 16px;
                }
            }

            .licensed-info-actions {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                height: 100%;
                width: 15%;

                button {
                    width: 100%;
                    border: 1px solid #000;
                    border-radius: 5px;
                    background-color: #fff;
                    color: #000;
                    padding: 5px 10px;
                    transition: transform 0.3s ease;
                    cursor: pointer;

                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    .pending-connections {
        height: 60%;
        width: 100%;

        .pending-connections-list {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding-top: 20px;
            display: inline-flex;
            gap: 10px;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;

            .store-box {
                padding: 15px 20px;
                width: 350px;
                background-color: #fff;
                border-radius: 5px;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.05);
                }

                .store-img {
                    height: 100%;
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .profile {
                        background-color: #fff;
                        border: 1px solid #000;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 80px;
                        width: 80px;

                        svg {
                            height: 30px;
                        }
                    }
                }

                .store-details {
                    padding-left: 10px;
                    height: 100%;
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    justify-content: center;
                    align-items: flex-start;

                    .store-name {
                        font-size: 16px;
                        width: 100%;
                        display: inline-flex;
                        justify-content: space-between;

                        svg {
                            cursor: pointer;
                            height: 18px;
                        }
                    }

                    .store-cell {
                        svg {
                            margin-right: 5px;
                            height: 14px;
                        }

                        font-size: 14px;
                    }

                    .store-licensed-fee {
                        svg {
                            margin-right: 5px;
                            height: 14px;
                        }

                        font-size: 14px;
                    }

                    .store-address {
                        display: inline-flex;
                        align-items: center;
                        width: 100%;

                        svg {
                            margin-right: 5px;
                            height: 14px;
                        }

                        span {
                            font-size: 12px;
                            font-weight: 300;
                        }
                    }

                    .store-actions {
                        width: 100%;
                        display: inline-flex;
                        gap: 10px;
                        align-items: center;
                        justify-content: space-between;

                        .status-connection {
                            border: none;
                            border-radius: 5px;
                            color: #fff;
                            padding: 5px 10px;
                            transition: transform 0.3s ease;
                            width: 100%;
                            text-align: center;
                        }

                        button {
                            border: none;
                            border-radius: 5px;
                            color: #fff;
                            padding: 5px 10px;
                            transition: transform 0.3s ease;
                            cursor: pointer;

                            &:hover {
                                transform: scale(1.05);
                            }
                        }

                        .connect-store {
                            width: 100%;
                            background-color: #50A773;
                        }
                    }
                }
            }
        }

    }
}

.loading-oval-content {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
}