

// Config Modal
.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-title {
    font-size: 20px;
    margin-bottom: 20px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60vh;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-menu {
    width: 20%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: start;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-menu .menu-item {
    margin: 10px 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    text-align: right;
    font-size: 14px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-menu .menu-item .config-menu-count {
    margin-left: 10px;
    color: white;
    background-color: #EA1D2C;
    padding: 4px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    font-size: 10px;
    border-radius: 100%;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.vertical-config-line {
    width: 0.2%;
    background-color: #DCDCDC;
    height: 100%;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 16px;
    justify-content: start;
    height: 100%;
    width: 80%;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .header-config {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .header-config .header-actions-buttons .back-button {
    padding: 8px 10px;
    background-color: #E2E6EA;
    color: black;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .header-config .header-actions-buttons .create-button {
    padding: 8px 10px;
    background-color: #50A773;
    color: white;
    border: none;
    border-radius: 5px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .title {
    font-size: 20px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 10px 0px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form {
    width: 100%;
    margin-top: 10px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form .ifood-form-control {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form .ifood-form-control label {
    font-size: 14px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form .ifood-form-control textarea {
    width: 100%;
    height: 200px;
    padding: 5px 10px;
    resize: none;
}


.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form .interruption-dates {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form .interruption-dates input {
    height: 30px;
    padding: 5px;
    font-size: 14px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form .ifood-form-actions {
    display: flex;
    width: 100%;
    justify-content: end;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content form .ifood-form-actions button {
    height: 30px;
    width: 50px;
    background-color: #50A773;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content .interruptions-list {
    font-size: 10px;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content .interruptions-list td {
    vertical-align: middle;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content .interruptions-list .delete-action {
    text-align: center;
}

.cancellation-ifood-modal-overlay .cancellation-ifood-modal-content
.config-content .content .not-interruptions {
    height: 100%;
    font-size: 18px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 24px;
}

// Loading
.loading-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}