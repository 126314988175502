.integrations-filters {
    width: 30%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .filters-fields {
        gap: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        padding-right: 10px;

        .filters-form {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;

            label {
                font-size: 10px;
                font-weight: 400;
                margin-bottom: 5px;
            }

            input {
                padding: 5px 10px;
                font-size: 12px;
                border-radius: 3px;
                border: none;
                width: 100%;
            }
        }

        .start-date, .end-date, .delivery-code  {
            width: 20%;
        }
        .customer-name {
            width: 35%;
        }
    }

    .filters-buttons {
        width: 10%;
        height: 100%;
        display: inline-flex;
        gap: 5px;
        align-items: flex-end;
        justify-content: space-between;

        button {
            height: 100%;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}