.animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    flex-direction: column;
    color: #fff;

    &__container {
        width: 15rem;
        height: 15rem;

        @media screen and (min-width: 900px) {
            width: 20rem;
            height: 20rem;
        }
    }
}
