@mixin animation-height($height-init, $height-end, $name) {
    @keyframes #{$name} {
        from {
            height: $height-init;
        }

        to {
            height: $height-end;
        }
    }
}

@keyframes show-field {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@mixin animation-fade-in($number, $time) {
    .animation--opacity-#{$number} {
        opacity: 0;
        animation: show-field 1s $time ease-in-out forwards;
    }
}

@keyframes button-fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@mixin button-animation($name, $delay) {
    &__animation-#{$name} {
        animation: button-fade-in 1s $delay forwards ease-in-out;
    }
}
