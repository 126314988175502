.management-order-details {
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
    background-color: #f2f2f2;
    padding: 20px 40px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .order-details-selected-order {
        height: 100%;

        header {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .requester-name {
                font-size: 18px;
                margin-bottom: 10px;
            }

            .close-modal {
                cursor: pointer;
                font-size: 24px;

                svg:hover {
                    color: #ccc;
                }
            }
        }
        .first-header,
        .second-header {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
        }

        .first-header span {
            margin-right: 10px;

            b {
                font-weight: bold;
            }
        }

        .second-header .header-box {
            background-color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            border-radius: 5px;
            height: 30px;
            margin-right: 10px;
        }

        .status-container {
            background-color: #fff;
            border-radius: 5px;
            padding: 10px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            .status-details {
                h6 {
                    font-size: 12px;
                }
    
                .status-text {
                    margin: 5px 0px;
                }
            }

            .driver-info {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;

                .driver-details {
                    line-height: 20px;
                    .driver-name {
                        font-size: 14px;
                    }

                    .driver-phone {
                        font-size: 12px;
                    }
                }

                .driver-icon {
                    margin-left: 10px;
                    
                    img {
                        height: 50px;
                    }
                }
            }
        }

        .customer-address-container {
            background-color: #fff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 10px;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            width: 100%;

            .address {
                width: 70%;
                display: inline-flex;
                height: 100%;
                align-items: center;
                justify-content: start;

                .location-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f2f2f2;
                    padding: 7px 10px;
                    height: 30px;
                    width: 30px;
                    border-radius: 50px;
                }

                span {
                    font-size: 12px;
                    margin-left: 5px;
                }
            }

            .delivery-type {
                background-color: #f2f2f2;
                padding: 7px 10px;
                border-radius: 5px;
                font-size: 12px;
                display: flex;
                align-items: center;
            }
        }

        .details-container {
            background-color: #fff;
            padding: 20px 0px 0px 0px;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            width: 100%;

            .line {
                height: 1px;
                background-color: #dcdcdc;
                position: relative;
                width: 100%;
            }

            .items-header {
                margin-bottom: 20px;
                padding-left: 20px;
                text-align: left;
                width: 100%;
            }

            .items-map {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-inline: 20px;
                width: 100%;

                .item-content {
                    width: 100%;
                    display: inline-flex;
                    align-items: start;
                    justify-content: space-between;
                    margin-bottom: 10px;

                    .item-details {
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        align-items: center;
                        width: 90%;

                        .main-item {
                            display: inline-flex;
                            justify-content: start;
                            align-items: center;
                            width: 100%;

                            .item-image {
                                background-color: #f2f2f2;
                                padding: 10px;
                                margin-right: 10px;
                            }

                            .item-quantity {
                                margin-right: 5px;
                            }
                        }

                        .item-observations {
                            background-color: #F2F2F2;
                            border-radius: 5px;
                            padding: 10px 15px;
                            width: 100%;
                            margin-left: 20%;
                        }

                        .item-options {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: end;
                            width: 90%;

                            .options-container {
                                display: inline-flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 90%;
                                height: 20px;
                                font-size: 12px;

                                .option-info {
                                    display: inline-flex;
                                    align-items: center;
                                    justify-content: center;

                                    .vertical-line {
                                        width: 1px;
                                        height: 20px;
                                        background-color: #dcdcdc;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .item-price {
                        width: 10%;
                    }
                }
            }

            .benefit-info .benefit-subtitle {
                margin-top: 5px;
            }
        }

        .price-container {
            padding: 10px 20px;
            min-height: 30px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            .payment-methods-info {
                display: inline-flex;
                align-items: center;
                justify-content: start;
                width: 80%;
            }
        }

        .pending-info-container {
            padding-inline: 20px;
            min-height: 30px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: start;
        }

        .cancel-order-container {
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            .cancel-order-button {
                height: 30px;
                padding: 5px 15px;
                margin-bottom: 10px;
                background: white;
                border: 1px solid #000;
                border-radius: 5px;
                color: #000;
            }
        }
    }

    .order-details-not-order {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        h1 {
            font-size: 20px;
        }

        h6 {
            font-size: 16px;
            margin-top: 10px;
        }
    }

    .order-details-follow-up {
        display: inline-flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .status-box {
            width: 24%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .status-circle-box {
                height: 70%;
                width: 100%;
                display: inline-flex;
                justify-content: flex-start;
                align-items: center;

                .circle-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40%;

                    .circle {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 50px;
                        width: 100%;
                        border-radius: 50px;
                        background-color: #ccc;

                        svg {
                            height: 15px;
                        }
                    }
                }



                .line {
                    height: 2px;
                    width: 60%;
                    background-color: #ccc;
                }
            }

            .status-info-text {
                width: 40%;
                margin-top: 10px;

                .status-name {
                    font-weight: 600;
                    font-size: 10px;
                    text-align: center;
                    line-height: 15px;
                    margin-bottom: 5px;
                }

                .status-time {
                    line-height: 15px;
                    font-weight: 500;
                    font-size: 10px;
                    text-align: center;
                }
            }

        }

        .status-box-active {
            .status-circle-box {
                .circle-container {
                    .circle {
                        background-color: #ccc;
                        animation: fill-left-to-right 0.5s forwards;
                    }
                }

                .line {
                    background-color: #ccc;
                    animation: fill-left-to-right 0.5s forwards;
                }
            }
        }

        .status-box-active:nth-child(1) {

            .circle-container .circle,
            .line {
                animation-delay: 0s;
            }
        }

        .status-box-active:nth-child(2) {

            .circle-container .circle,
            .line {
                animation-delay: 0.5s;
            }
        }

        .status-box-active:nth-child(3) {

            .circle-container .circle,
            .line {
                animation-delay: 1s;
            }
        }

        .status-box-active:nth-child(4) {

            .circle-container .circle,
            .line {
                animation-delay: 1.5s;
            }
        }

        .status-box-active:nth-child(5) {

            .circle-container .circle,
            .line {
                animation-delay: 2s;
            }
        }
    }

    @keyframes fill-left-to-right {
        0% {
            background-color: #ccc;
            background-size: 0 100%;
        }

        100% {
            background-color: #000;
            background-size: 100% 100%;
        }
    }
}