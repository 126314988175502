.config-container {
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2% 5%;

    .config-nav-bar {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
        border-bottom: 1px solid #000;

        .config-nav-item {
            display: inline-flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
            background-color: #000;
            color: #fff;
            padding: 10px 20px;
            border-radius: 5px 5px 0px 0px;
            transition: transform 0.3s ease;
            cursor: pointer;

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .config-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 20px 0px;
    }
}