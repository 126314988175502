.integrations-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .integrations-header {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 90%;

        .back-page {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }

        .integrations-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .integrations-table {
        width: 100%;
        max-height: 60%;
        overflow-y: auto;
        font-size: 12px;
    }
    
    .integrations-table .integration-id span {
        background-color: #FFC107;
        padding: 5px 10px;
        border-radius: 5px;
        color: #000;
    }
    
    .integrations-table .action-button button {
        background-color: #50A773;
        border: none;
        border-radius: 5px;
        color: #fff;
        padding: 5px 10px;
    }

    .pagination-container {
        height: 5%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        span {
            font-size: 14px;
            font-weight: 500;
        }

        button {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }
    }
}
