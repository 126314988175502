.my-delivery-container {
    .mydelivery-section-1{
        background-color: #232323;
        height: 664px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    
    .titulo-mydelivery{
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin: 0px 0px 50px 50px;
        font-size: 42px;
        max-width: 500px;
        color: white;
        line-height: 1.5cm;
    }
}