.select {
    background-color: var(--branco);
    border-radius: 0.4rem;
    font-family: var(--main-font);
    font-size: 1rem;
    font-weight: var(--peso-light);
    height: 3rem;
    margin-bottom: 1rem;
    text-indent: 1rem;
    width: 100%;
    cursor: pointer;

    transition: opacity 1s ease-in-out;

    &__label {
        display: none;
    }

    &__container-troco {
       height: 0;
       overflow: hidden;
       transition: height 1s ease-in-out;

       &--active{
           height: 5rem;
       }
    }
}