.ifood-orders-container .ifood-order-details {
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
    background-color: #f2f2f2;
    padding: 20px 40px;
    height: 80%;
    width: 65%;
    overflow-y: auto;
    overflow-x: hidden;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order {
    height: 100%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .requester-name {
    font-size: 18px;
    margin-bottom: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .first-header,
.ifood-orders-container .ifood-order-details
.order-details-selected-order .second-header {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .first-header span {
    margin-right: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .first-header span b {
    font-weight: bold;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .second-header
.header-box {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    height: 30px;
    margin-right: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .status-container {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .status-container
h6 {
    font-size: 12px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .status-container
.status-text  {
    margin: 5px 0px
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .status-container
.status-text strong {
    font-size: 16px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .customer-address-container {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100%;   
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .customer-address-container
.address {
    width: 70%;
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: start;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .customer-address-container
.address .location-icon {
    background-color: #f2f2f2;
    padding: 7px 10px;
    border-radius: 50px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .customer-address-container
.address span {
    margin-left: 5px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .customer-address-container
.delivery-type {
    background-color: #f2f2f2;
    padding: 7px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container {
    background-color: #fff;
    padding: 20px 0px 0px 0px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;   
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.line {
    height: 1px;
    background-color: #dcdcdc;
    position: relative;
    width: 100%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-header {
    margin-bottom: 20px;
    padding-left: 20px;
    text-align: left;
    width: 100%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 20px;
    width: 100%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content {
    width: 100%;
    display: inline-flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 90%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .main-item {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .main-item .item-image {
    background-color: #f2f2f2;
    padding: 10px;
    margin-right: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .main-item .item-quantity {
    margin-right: 5px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .item-observations {
    background-color: #F2F2F2;
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
    margin-left: 20%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .item-options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    width: 90%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .item-options
.options-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 20px;
    font-size: 12px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .item-options
.options-container .option-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-details .item-options
.options-container .option-info .vertical-line {
    width: 1px;
    height: 20px;
    background-color: #dcdcdc;
    margin-right: 10px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.benefit-info .benefit-subtitle {
    margin-top: 5px;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .details-container
.items-map .item-content .item-price {
    width: 10%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .price-container {
    padding: 10px 20px;
    min-height: 30px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .price-container
.payment-methods-info {
    display: inline-flex;
    align-items: center;
    justify-content: start;
    width: 80%;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .pending-info-container {
    padding-inline: 20px;
    min-height: 30px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: start;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .cancel-order-container {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.ifood-orders-container .ifood-order-details
.order-details-selected-order .cancel-order-container
.cancel-order-button {
    height: 30px;
    padding: 5px 15px;
    margin-bottom: 10px;
    background: white;
    border: 1px solid #EA1D2C;
    border-radius: 5px;
    color: #EA1D2C;
}

.ifood-orders-container .ifood-order-details
.order-details-not-order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.ifood-orders-container .ifood-order-details
.order-details-not-order h1 {
    font-size: 20px;
}

.ifood-orders-container .ifood-order-details
.order-details-not-order h6 {
    font-size: 16px;
    margin-top: 10px;
}