.text-area {
    display: block;
    position: relative;
    width: 100%;

    &__input {
        border-radius: 0.4rem;
        border: 1px solid var(--cinza-claro);
        font-family: "Poppins", sans-serif;
        height: 4.5rem;
        overflow: auto;
        padding: 2.5rem 1rem 0.5rem;
        resize: both;
        resize: none;
        width: 100%;
    }

    &__label {
        background-color: var(--branco);
        border-top-left-radius: 0.4rem;
        color: var(--preto);
        font-size: 14px;
        font-family: var(--main-font);
        font-weight: var(--peso-light);
        height: 2.2rem;
        left: 2px;
        padding: .5rem 0 0 1rem;
        position: absolute;
        top: 2px;
        width: calc(100% - 1rem);
    }
}

.field_optional {
    font-size: 10px;
    color: #7b7a7a;
    margin-left: 5px;
}
