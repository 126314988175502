$desktop: 900px;
$laptop: 1536px;
$mobile: 505px;

.follow-up-container {
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 5% 2%;

    .filter-buttons-container {
        width: 100%;
    display: inline-flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-start;
        margin-bottom: 5%;

        .filter-box {
            border: 2px solid black;
            background-color: green;
            height: 100px;
            width: 120px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }

            .filter-box-count {
                font-size: 24px;
                margin-bottom: 5px;
                font-weight: 600;
            }

            .filter-box-text {
                text-align: center;
                counter-reset: #453F51;
                font-size: 16px;
                font-weight: 300;
            }
        }
    }
}

.content-buttons-header {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;

    button {
        font-size: 12px;
        margin-bottom: -30px;
        background-color: #000;
        font-weight: 500;
    }
}

.group-orders-button {
    background-color: #000;
    height: 30px;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    color: #fff;
    font-size: 14px;
}

.swal2-container {
    font-family: var(--main-font);
}

@media screen and (min-width: 506px) and (max-width: 1024px) {
    .table-orders-container table {
        font-size: 10px;
    }

    table td {
        padding: 5px 5px 5px 10px;
    }

    table .td-actions-buttons button {
        height: 20px;
        width: 10px;
        padding-right: 20px;
        display: inline-flex;
        align-items: center;
    }

    table .td-actions-buttons button svg, table .td-status svg {
        height: 10px;
        width: 10px;
    }

    table .td-status svg {
        margin-bottom: -5px !important;
    }

    .follow-up-container {
        min-height: 642px !important;
    }
}


@media screen and (max-width: $mobile) {
    .table-orders-container table {
        font-size: 10px;
    }

    table td {
        padding: 5px 5px 5px 10px;  
    }

    .follow-up-container {
        margin-top: 20% !important;
    }

    table .td-actions-buttons button {
        height: 20px;
        width: 10px;
        padding-right: 20px;
        display: inline-flex;
        align-items: center;
    }

    table .td-actions-buttons .cancel-order-btn {
        margin-left: 0;
        margin-top: 5px;
    }

    table .td-actions-buttons button svg {
        height: 10px;
        width: 10px;
    }

    table .td-status svg {
        height: 15px;
        width: 15px;
        margin-top: 5px;
        margin-left: 15px;
    }

    table .td-status svg {
        margin-bottom: -5px !important;
    }

    .follow-up-container {
        min-height: 782px !important;
    }
}