@font-face {
    font-family: iFoodRCTextos-Regular;
    src: url("../fonts/ifoodRcFont/iFoodRCTextos-Regular.woff") format("woff");
}

:root {
    --main-font: "Poppins", sans-serif !important;
    --secondary-font: "Open Sans", sans-serif;
    --header-font: "Nunito Sans", sans-serif;
    --alternate-font: "Montserrat", sans-serif;

    --light: 300;
    --regular: 400;
    --medium: 500;
    --semi-bold: 600;
    --bold: 700;

    --border-color: #909090;
    --background-body: #F2F2F2;
    --outline-button-active: #fdaf48;

    --cinza: #595858;
    --cinza-claro: #c4c4c4;
    --preto: #000;
    --branco: #fff;
    --background-footer: linear-gradient(to top, #0e1025, #0e0e22, #0d0c1e, #0c0a1b, #0a0818);

    --success: #08a01079;

    --header-height: 11rem;
    --header-height-desktop: 12vh;

}

.main {
    width: 100vw;
    background-color: var(--branco);
    margin: 0 auto;
    height: 100vh;

    @media screen and (min-width: 900px) {
        align-items: center;
        background: transparent;
        box-shadow: none;
        display: flex;
        padding: 0;
        transform: none;
        z-index: 0;
    }
}

@keyframes open {
    to {
        opacity: 1;
    }
}

* {
    box-sizing: border-box;
    font-family: var(--main-font) !important;
}

body {
    background-color: var(--background-body);
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spinner-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
}

.loading-image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%, -50%);
    animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
    0% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
    50% { transform: translate(-50%, -50%) scale(1.2); opacity: 0.7; }
    100% { transform: translate(-50%, -50%) scale(1); opacity: 1; }
}

.container-duo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
}

.root {
    width: 100vw;
    height: 100vh;
    margin: 0;
}

.content-container {
    height: 100%;
    width: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 5px;
    height: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #d1d0d0;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}