.my-drivers-container {
    width: 100%;

    .connection-list {
        .vacancy {
            width: 100%;
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;

            button {
                padding: 5px 15px;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                border: none;
                border-radius: 5px;
                font-size: 14px;
                color: #fff;
                transition: transform 0.3s ease;
                background-color: #000;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }

        .connection-box-list {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: flex-start;

            .connection-box {
                padding: 15px 20px;
                width: 350px;
                background-color: #fff;
                border-radius: 5px;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                transition: transform 0.3s ease;
    
                &:hover {
                    transform: scale(1.05);
                }
    
                .driver-img {
                    height: 100%;
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    .profile {
                        background-color: #fff;
                        border: 1px solid #000;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 80px;
                        width: 80px;
    
                        img {
                            height: 50px;
                        }
                    }
                }
    
                .driver-details {
                    padding-left: 10px;
                    height: 100%;
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    justify-content: center;
                    align-items: flex-start;
    
                    .driver-name {
                        font-size: 16px;
                        width: 100%;
                        display: inline-flex;
                        justify-content: space-between;
    
                        svg {
                            cursor: pointer;
                            height: 18px;
                        }
                    }
    
                    .driver-cell,
                    .driver-count-deliveries {
                        svg {
                            margin-right: 5px;
                            height: 14px;
                        }
    
                        font-size: 14px;
                    }
    
                    .driver-actions {
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
    
                        button {
                            border: none;
                            border-radius: 5px;
                            color: #fff;
                            padding: 5px 10px;
                            transition: transform 0.3s ease;
                            cursor: pointer;
    
                            &:hover {
                                transform: scale(1.05);
                            }
                        }
    
                        .accept-driver,
                        .refuse-driver {
                            width: 48%;
                        }
    
                        .connected-driver,
                        .disconnected-driver,
                        .disapprove-driver {
                            font-size: 14px;
                            font-weight: 500;
                            width: 100%;
                            border: none;
                            color: #fff;
                            text-align: center;
                            border-radius: 5px;
                            padding: 8px 0px;
                        }
    
                        .disconnected-driver {
                            background-color: #ccc;
                        }
    
                        .accept-driver,
                        .connected-driver {
                            background-color: #50A773;
                        }
    
                        .refuse-driver,
                        .disapprove-driver {
                            background-color: #EA1D2C;
                        }
                    }
                }
            }
        }

    }
}