.register-support-container {
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    width: 100%;

    .support-form {
        height: 100%;
        width: 40%;

        .title {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            .back-page {
                height: 30px;
                width: 30px;
                border: 1px solid#ccc;
                border-radius: 50%;
                color: #fff;
                transition: transform 0.3s ease;
                background-color: #fff;
                margin-right: 10px;
    
                &:hover {
                    transform: scale(1.1);
                }
    
                svg {
                    color: #000;
                }
            }
        }

        .form-control {
            width: 100%;
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;

            select {
                width: 50%;
                height: 35px;
                padding: 0px 10px;
                border-radius: 5px;
            }

            label {
                margin-bottom: 10px;
                font-size: 16px;
            }

            textarea {
                height: 10rem;
                padding: 10px;
            }
        }

        button {
            background-color: #000;
            color: #fff;
            font-weight: 500;
            border: none;
            border-radius: 5px;
            height: 35px;
            width: 100px;
        }
    }

    .support-list {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 55%;
        height: 100%;

        .title {
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 20px;
        }
    }
}