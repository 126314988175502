.order-card {
    background-color: #fff;
    padding: 20px;
    width: 30%;
    border-radius: 5px;
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }

    .order-card-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        .order-card-key {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            span {
                font-size: 14px;
                font-weight: 300;
            }

            svg {
                color: #DBA827;
                margin-right: 5px;
            }
        }

        .return-code {
            background-color: #d9534f;
            padding: 10px 10px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: center;
            align-items: center;
            color: #fff;
            
            .return-code-title {
                text-align: center;
                font-size: 10px;
                font-weight: 300;
            }

            .return-code-text {
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .order-card-actions {
            display: inline-flex;
            align-items: center;

            .order-sales-channel {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px 10px;
                border-radius: 5px;

                img {
                    height: 12px;
                }
            }

            .action {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-radius: 5px;
                cursor: pointer;

                svg {
                    font-size: 14px;
                }
            }
        }
    }

    .order-card-body {
        margin-top: 10px;

        .order-store {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .order-store-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #F9FAFB;
                padding: 8px;
                width: 30px;
                border-radius: 50px;
                margin-right: 10px;

                svg {
                    font-size: 14px;
                    color: #000;
                }
            }

            .order-store-content {
                .order-store-name {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }

        .order-details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 15px;

            .order-payment {
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: space-between;

                .method  {
                    font-size: 10px;
                    width: 70%;
                    text-align: left;
                }

                .value {
                    width: 30%;
                    text-align: right;
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            .collection-confirmation-code {
                width: 50%;
                color: #fff;
                font-weight: 500;
                margin-top: 10px;
                text-align: center;
                border-radius: 5px;
                padding: 5px 0px;
                background-color: #DBA827;
            }
        }

        .delivery-at {
            margin-top: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            .delivery-at-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #F9FAFB;
                padding: 8px;
                width: 30px;
                border-radius: 50px;
                margin-right: 10px;

                svg {
                    font-size: 14px;
                    color: #000;
                }
            }

            .delivery-at-content {
                .delivery-at-customer {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .delivery-at-address {
                    font-size: 12px;
                    font-weight: 300;

                }
            }
        }

        .driver-info {
            margin-top: 10px;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            .driver-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #F9FAFB;
                padding: 8px;
                width: 30px;
                border-radius: 50px;
                margin-right: 10px;

                img {
                    height: 14px;
                }
            }

            .driver-content {
                .driver-name {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }

                .driver-phone {
                    font-size: 12px;
                    font-weight: 300;
                }
            }
        }
    }

    .order-card-footer {
        margin-top: 5px;
        width: 100%;

        .order-card-status-container {
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .order-card-status {
                display: inline-flex;
                align-items: center;
                width: 60%;

                .status-point {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    margin-right: 5px;
                    animation: growDecrease 2s infinite;
                }

                @keyframes growDecrease {
                    0% {
                        transform: scale(1);
                    }

                    50% {
                        transform: scale(1.2);
                    }

                    100% {
                        transform: scale(1);
                    }
                }

                span {
                    font-size: 12px;
                    margin-left: 5px;
                }
            }

            .order-card-date {
                width: 40%;
                font-size: 10px;
                font-weight: 400;
                text-align: right;
            }
        }

        .order-card-button {
            margin-top: 10px;
            border: none;
            border-radius: 5px;
            // background-color: #EA1D2C;
            background-color: #50A773;
            color: #fff;
            font-weight: 500;
            padding: 10px 0px;
            width: 100%;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.02);
            }
        }

    }
}