.main .ifood-orders-container {
    height: 100%;
    width: 100%;
    padding-inline: 5rem;
    display: inline-flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
}

.ifood-orders-container .is-open-store {
    background-color: #50A773;
    max-width: 100px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    float: right;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ifood-orders-container .is-closed-store {
    background-color: #EA1D2C;
    max-width: 100px;
    margin-bottom: 10px;
    float: right;
    padding: 5px;
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ifood-orders-container .ifood-orders-list-container {
    background-color: white;
    height: 80%;
    width: 35%;
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
    z-index: 2;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.ifood-orders-container .ifood-orders-list-container
.orders-list-title {
    font-size: 24px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.ifood-orders-list-container .main-list-box {
    background-color: #DCDCDC;
    padding: 10px 5px;
    border-radius: 10px;
    margin-top: 10px;
}

.ifood-orders-list-container .main-list-box 
.header-box {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    height: 20px;
}

.ifood-orders-list-container .main-list-box 
.header-box .header-content {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 15px;
}

.ifood-orders-list-container .main-list-box 
.header-box .header-content
.header-text {
    font-size: 14px;
    margin-right: 10px;
}

.ifood-orders-list-container .main-list-box 
.header-box .header-content
.header-count {
    font-size: 14px;
    background-color: #717171;
    border-radius: 15px;
    padding: 2px 10px;
    color: #DCDCDC;
}

.ifood-orders-list-container .main-list-box 
.header-box .header-icon {
    width: 10%;
    display: flex;
    padding-right: 15px;
    align-items: center;
    justify-content: center;
}

.ifood-orders-list-container .main-list-box 
.header-box .header-icon svg {
    cursor: pointer;
}

.ifood-orders-list-container .main-list-box
.box {
    cursor: pointer;
    margin: 10px;
    border-radius: 10px;
    background-color: white;
    padding: 10px 15px;
}

.ifood-orders-list-container .main-list-box
.box .pending-info {
    display: inline-flex;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ifood-orders-list-container .main-list-box
.box .cancelled-info,
.ifood-orders-list-container .main-list-box
.box .confirmed-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.ifood-orders-list-container .main-list-box
.box .cancelled-info .order-code,
.ifood-orders-list-container .main-list-box
.box .confirmed-info .order-code {
    font-size: 14px;
}

.ifood-orders-list-container .main-list-box
.box .cancelled-info .order-status,
.ifood-orders-list-container .main-list-box
.box .confirmed-info .order-delivery-forecast {
    font-size: 12px;
    color: #717171;
    font-weight: 500;
    margin-top: 5px;
}

.ifood-orders-list-container .main-list-box
.box .cancelled-info .order-status,
.ifood-orders-list-container .main-list-box
.box .confirmed-info .pickup-code {
    background-color: #F2F2F2;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 15%;
    border-radius: 5px;
    margin-top: 10px;

}

.ifood-orders-list-container .main-list-box
.box .cancelled-info .order-status,
.ifood-orders-list-container .main-list-box
.box .confirmed-info .pickup-code .pickup-code-title {
    font-size: 12px;
}

.ifood-orders-list-container .main-list-box
.box .cancelled-info .order-status,
.ifood-orders-list-container .main-list-box
.box .confirmed-info .pickup-code .pickup-code-detail {
    font-size: 16px;
    margin-top: 5px;
}


.ifood-orders-list-container .main-list-box
.box .cancelled-info .order-status,
.ifood-orders-list-container .main-list-box
.box .confirmed-info .takeout-label,
.ifood-orders-list-container .main-list-box
.box .cancelled-info .order-status,
.takeout-label {
    display: inline-flex;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}

.ifood-orders-list-container .main-list-box
.box .order-code {
    font-size: 16px;
    font-weight: 500;
}

.ifood-orders-list-container .main-list-box
.box .pending-info .wait-time-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 40px;
    height: 60%;
    border-radius: 5px;
}

.ifood-orders-list-container .main-list-box
.box .pending-info .wait-time-box .count-time {
    font-size: 18px;
    font-weight: 500;
    color: white;
}

.ifood-orders-list-container .main-list-box
.box .pending-info .wait-time-box .count-label {
    font-size: 12px;
    font-weight: 500;
    color: white;
}

.ifood-orders-list-container .main-list-box
.box .action-button {
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
    height: 30px;
    font-size: 14px;
    color: white;
}

.ifood-orders-list-container .main-list-box
.box .accept-button {
    background-color: #EA1D2C;
}

.ifood-orders-list-container .main-list-box
.box .dispatch-button {
    background-color: #50A773;
}