.cancellation-ifood-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fundo escuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: var(--main-font);
}

.cancellation-ifood-modal-content {
    background-color: white;
    padding: 20px;
    margin-left: 20vh;
    border-radius: 8px;
    width: 120vh;
    /* Largura do modal */
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.cancellation-ifood-modal-title {
    font-size: 18px;
    margin-bottom: 20px;
}

.cancellation-ifood-modal-danger {
    color: #EA1D2C;
    margin-bottom: 15px;
}

.cancellation-ifood-modal-subtitle {
    font-size: 12px;
    margin-bottom: 15px;
}

.cancellation-ifood-modal-close {
    position: absolute;
    top: 15px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
}

.cancellation-ifood-radio-select {
    display: flex;
    flex-wrap: wrap;
    /* Permite que os itens pulem para a próxima linha quando necessário */
    gap: 10px;
    /* Espaçamento entre os itens */
    max-width: 100%;
    /* Define um limite de largura para o contêiner */
    margin: 20px auto;
    /* Centraliza o contêiner na tela */
}

.loading-cancellations-reasons-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 180px;
}

.cancellation-ifood-radio-select .map-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 30%;
}

.cancellation-ifood-radio-select .map-option input {
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.cancellation-ifood-radio-select .map-option label {
    font-size: 12px;
    margin-left: 5px;
}

.cancellation-ifood-modal-actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}

.cancellation-ifood-modal-actions .modal-back-action {
    height: 40px;
    padding: 5px 10px;
    margin-right: 10px;
    background: transparent;
    font-size: 14px;
    border-radius: 3px;
    color: #EA1D2C;
    font-weight: bold;
    border: 1px solid #EA1D2C;
}

.cancellation-ifood-modal-actions .modal-cancel-action {
    height: 40px;
    padding: 5px 20px;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #DCDCDC;
}