.logs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .activity-logs-header {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-bottom: 3%;

        .back-page {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }

        .activity-logs-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .activity-logs-list {
        width: 100%;
        font-size: 14px;

        .status-label {
            text-align: center;
        }
    }

}