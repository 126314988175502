.notification-container {
    position: fixed;
    bottom: 0;
    right: 0;
    height: auto;
    width: 300px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    transition: top 0.5s ease;
}

.notification-container .notification-title {
    margin-bottom: 10px;
    font-size: 18px;
}