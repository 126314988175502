@import './../../../assets/css/mixin.scss';

.button {
  background: transparent;
  border: 1px solid rgb(184, 184, 184);
  width: 100%;
  margin: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.4rem;
  font-family: var(--secondary-font);
  transition: background-color 500ms ease-in-out, color 300ms ease-in-out;
  opacity: 0;

  @media screen and (min-width: 900px) {
    margin-bottom: 0;
  }

  @include button-animation(1, 3s);
  @include button-animation(2, 300ms);

  &--small {
    max-width: 400px;
  }

  &--active {
    background-color: #000;
    color: #fff;
    font-weight: 700;
  }

  &--help {
    width: 100%;
    text-align: center;
    background-color: transparent;
    border: none;
    font-family: var(--main-font);
    margin: 2rem 0 1rem;
  }
}
