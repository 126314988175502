@import "./validation.scss";

.field_optional {
    font-size: 10px;
    color: #7b7a7a;
    margin-left: 5px;
}

.field {
    display: block;
    margin-bottom: 1rem;
    opacity: 1;
    position: relative;
    width: 100%;

    &__input {
        border-radius: 0.6rem;
        border: 1px solid var(--cinza-claro);
        font-family: "Poppins", sans-serif;
        font-size: var(--tamanho-padrao);
        height: 3rem;
        outline: transparent;
        padding-top: 1rem;
        text-indent: 1rem;
        transition: height 200ms ease-in, text-indent 500ms ease-in-out, padding-top 500ms ease-in-out, border 500ms ease-in-out;
        width: 100%;

        &[name="state"] {
            text-transform: uppercase;
        }

        &:focus,
        &:not(:placeholder-shown) {
            height: 4rem;
        }

        &:not(:focus):not(:placeholder-shown) {
            height: 4rem;
        }

        &:focus ~ .field__label,
        &:not(:placeholder-shown) ~ .field__label {
            font-size: 0.9rem;
            top: 1rem;
        }
    }

    &__label {
        color: var(--preto);
        font-family: var(--main-font);
        font-size: 14px;
        font-weight: var(--peso-light);
        left: 1rem;
        position: absolute;
        top: 1.5rem;
        transform: translateY(-50%);
        transition: top 800ms ease-in-out, font-size 500ms ease-in-out;
    }
}

@include animation-fade-in(0, 0s);
@include animation-fade-in(1, 2s);
@include animation-fade-in(2, 2.2s);
@include animation-fade-in(3, 2.4s);
@include animation-fade-in(4, 2.6s);
@include animation-fade-in(5, 2.8s);
@include animation-fade-in(6, 3s);

