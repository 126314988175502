.pending-users-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .pending-users-header {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 95%;

        .back-page {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }

        .pending-users-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .pending-user-filters {
        width: 100%;
        margin: 10px 0;
        display: flex;
        justify-content: flex-end;
    }
}