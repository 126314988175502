.wallet-container {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    height: 100%;

    .main-balance-container {
        height: 100%;
        width: 50%;
        padding: 4%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .balance-info-container {
            background-color: #000;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            height: 30%;
            padding: 40px;
            border-radius: 20px;

            .balance-info-header {
                display: inline-flex;
                justify-content: space-between;
                width: 100%;

                span {
                    font-size: 18px;
                    color: #fff;
                    font-weight: 400;
                }

                svg {
                    height: 20px;
                    color: #fff;
                }
            }

            .balance-info-amount-container {
                display: inline-flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                .balance-info-amount {
                    .amount-title {
                        font-size: 24px;
                        color: #fff;
                        font-weight: 500;
                        margin-bottom: 1%;
                    }

                    .amount-value {
                        color: #fff;
                        font-size: 40px;
                        font-weight: 500;
                    }
                }

                .hide-amount {
                    cursor: pointer;
                    color: #fff;
                    margin-top: 20px;
                    margin-left: 20px;

                    svg {
                        height: 20px;
                    }
                }
            }
        }

        .balance-actions-container {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            height: 50%;
            width: 100%;

            .action-item {
                background-color: #fff;
                border-radius: 10px;
                height: 120px;
                width: 130px;
                box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                cursor: pointer;

                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.1);
                }

                .action-icon {
                    color: #000;

                    svg {
                        height: 32px;
                    }
                }

                .action-text {
                    color: #000;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    margin-top: 5px;
                    text-align: center;
                }
            }
        }
    }

    .transactions-container {
        display: flex;
        padding: 2%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        width: 50%;

        .transactions-title {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        .transactions-table {
            width: 100%;

            .loading-transactions {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 80%;
            }

            table {
                font-size: 10px;

                thead tr {
                    .transaction-date {
                        width: 20%;
                    }

                    .transaction-type {
                        width: 15%;
                    }

                    .transaction-value {
                        width: 20%;
                    }

                    .transaction-description {
                        width: 30%;
                    }

                    .transaction-status {
                        width: 15%;
                        text-align: center;
                    }
                }

                tbody tr {

                    .transaction-type-credit,
                    .transaction-value-credit {
                        color: green;
                        font-weight: 500;
                    }

                    .transaction-type-debit,
                    .transaction-value-debit {
                        color: red;
                        font-weight: 500;
                    }

                    .transaction-status {
                        text-align: center;
                    }
                }
            }

            .pagination-container {
                height: 5%;
                width: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                span {
                    font-size: 14px;
                    font-weight: 500;
                }

                button {
                    height: 30px;
                    width: 30px;
                    border: 1px solid#ccc;
                    border-radius: 50%;
                    color: #fff;
                    transition: transform 0.3s ease;
                    background-color: #fff;

                    &:hover {
                        transform: scale(1.1);
                    }

                    svg {
                        color: #000;
                    }
                }
            }
        }

    }
}

.pix-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pix-modal-value {
        font-size: 20px;
        font-weight: 600;
    }

    .pix-modal-id {
        font-size: 14px;
    }
}