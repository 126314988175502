.map {
    .gm-style-iw {
        display: none !important;
    }

    .distance-info-window {
        display: inline-flex;
    }
    
    .distance-info-window p{
        color: #000;
        font-weight: bold;
        margin-left: 5px;
    }
    
    .duration-info-window {
        font-size: 10px;
    }
    
    .gm-ui-hover-effect {
        display: none !important;
    }
    
    .gm-style .gm-style-iw-c {
        padding: 3px;
        border-radius: 0;
    }
    
    .gm-style-iw-d {
        padding: 5px;
        overflow: auto !important;
    }
}