.manage-users-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;

    .form-title {
        font-size: 14px;
        font-weight: 500;
    }

    .form-line {
        background-color: #ccc;
        width: 100%;
        height: 1px;
        margin: 10px 0px;
    }

    .manage-users-header {
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: 95%;
        margin-bottom: 3%;

        .back-page {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }

        .manage-users-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    form {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        .form-control {
            height: 60px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;

            label {
                font-size: 14px;
                margin-bottom: 5px;
            }

            input, select {
                height: 30px;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 10px;
            }

            button {
                background-color: #000;
                color: #fff;
                border: none;
                border-radius: 5px;
                padding: 10px 20px;
            }
        }
    }
}