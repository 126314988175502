.reports-container {
    .graphics-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        gap: 5%;
    
    }
    
    h1{
        text-align: center;
        font-size: 28px;
        font-weight: 500;
        margin: 5%;
    }
    .debtors-ranking{
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        
        .debtors-button{
            background-color: black;
            color: white;
            border-radius: 5%;
            padding: 2% 2%;
        }
    }
}
