.custom-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: white;
  width: 20vw;

  .logo {
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    font-weight: 300;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;

    img {
      height: 30px;
    }
  }
}

.collapsed-sidebar {
  width: 80px;
}

.custom-sidebar .sidebar {
  height: 100%;
  width: 20vw;
  min-width: 20vw;
}

.custom-sidebar .sidebar .menu {
  height: 80%;
}

.custom-sidebar .sidebar .menu ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  height: 100%;
}

.logo h1 {
  margin: 0;
  font-size: 16px;
  white-space: nowrap;
}

.logo.collapsed h1 {
  font-size: 24px;
}

.menu a {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  display: block;
  padding-left: 0px;
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease;

  .ps-menu-label {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.menu-icon {
  width: 18px;
  height: 16px;
  color: #000;
}

.menu a:hover {
  text-decoration: none;
}

.menu-item {
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  cursor: pointer;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 10px;

  .select-store {
    width: 90%;

    .ps-menu-label {
      color: #fff;
    }
  }

  .logout {
    background-color: #000;
    width: 90%;
    border-radius: 5px;
    padding: 2px;
    
    .menu-item a,
    .menu-icon {
      color: #fff;
    }
    
    .menu-item a:hover {
      background-color: #1a1a1a;
      border-radius: 5px;
    }

  }
}
