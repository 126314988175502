.platform-fees-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .fees-header {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-bottom: 10px;

        .back-page {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }

        .fees-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .fees-content {
        height: 90%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        .delivery-fees-inputs {
            height: 100%;
            width: 40%;
            display: flex;
            padding: 5%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .mode {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                justify-content: center;
                margin-bottom: 30px;

                svg {
                    font-size: 24px;
                }

                .mode-text {
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                }

                .mode-warning {
                    font-size: 14px;
                    font-weight: 300;
                    text-align: center;
                    line-height: 18px;
                }
            }

            .fee-input {
                margin-bottom: 10px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                svg {
                    height: 20px;
                }
            }

            .delivery-fees-actions {
                height: 50%;
                width: 100%;
                display: inline-flex;
                gap: 20px;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
        
                button {
                    background-color: #fff;
                    border-radius: 10px;
                    height: 100px;
                    width: 110px;
                    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    cursor: pointer;
                    transition: transform 0.3s ease;
        
                    svg {
                        height: 20px;
                    }
        
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }
        
    
        .delivery-fees-list {
            height: 100%;
            width: 60%;

            h4 {
                font-size: 20px;
                text-align: center;
                margin-bottom: 10px;
                font-weight: 500;
            }
    
            .td-actions {
                text-align: center;
            }
    
            .fee-remove-btn {
                background-color: #EA1D2C;
                border: none;
                border-radius: 5px;
                color: #fff;
                padding: 5px 10px;
                transition: transform 0.3s ease;
                cursor: pointer;
    
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }
}