.partners-container {
    .partners-section-1{
        background-color: #F6F6F6;
        height: 664px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .titulo-partners{
        font-size: 48px;
        font-weight: bold;
        margin: 0px 0px 20px 0px;
    }
    
    .subtitulo-partners{
        font-size: 35px;
        margin: 0px 0px 30px 0px;
        font-weight: 350;
    }
    
    .benefit-partners{
        font-size: 20px;
        font-weight: 350;
        margin: 0px 0px 10px 0px;
    }
    
    .check{
        height: 15px;
        width: 15px;
        margin: 0px 5px 0px 0px;
    }
    
    .button-partners{
        background-color: black;
        color: white;
        border: 2px solid black;
        border-radius: 5px; /* Arredondar os cantos */
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        margin: 20px 0px 0px 0px;
        padding: 10px;
        font-size: 17px;
    }
    
    .partners-section-1-lg{
        background-color: #F6F6F6;
        height: 450px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .titulo-partners-lg{
        font-size: 38px;
        font-weight: bold;
        margin: 0px 0px 20px 0px;
    }
    
    .subtitulo-partners-lg{
        font-size: 19px;
        margin: 0px 0px 30px 0px;
        font-weight: 500;
    }
    
    .benefit-partners-lg{
        font-size: 20px;
        font-weight: 350;
        margin: 0px 50px 10px 50px;
        text-align: center;
    }
    
    .check-lg{
        height: 15px;
        width: 15px;
        margin: 0px 5px 0px 0px;
    }
    
    .partners-section-2{
        background-color: #232323;
        height: 664px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    
    .titulo-partners-section-2{
        color: white;
        font-size: 40px;
        font-weight: bold;
        margin: 0px 0px 50px 0px;
    }
    
    .subtitulo-partners-section-2{
        color: white;
        font-size: 23px;
        margin: 0px 30px 50px 30px;
        text-align: center;
    }
    
    .button-partners-section-2{
        background-color: white;
        color: black;
        border: none;
        border-radius: 5px; /* Arredondar os cantos */
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        margin: 20px 0px 0px 0px;
        padding: 15px;
        font-size: 17px;
        font-weight: 500;
    }
    
    .partners-section-2-lg{
        background-color: #232323;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    
    .secondary-section-2-lg{
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .titulo-partners-section-2-lg{
        color: black;
        font-size: 38px;
        font-weight: bold;
        margin: 50px 0px 50px 0px;
    }
    
    .subtitulo-partners-section-2-lg{
        color: black;
        font-size: 23px;
        margin: 0px 30px 50px 30px;
        text-align: center;
    }
    
    .button-partners-section-2-lg{
        background-color: black;
        color: white;
        border: none;
        border-radius: 50px; /* Arredondar os cantos */
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        margin: 20px 0px 50px 0px;
        padding: 15px;
        font-size: 16px;
        font-weight: 500;
    }
    
    .partners-section-3{
        background-color: #232323;
    }
    
    .titulo-partners-section-3{
        text-align: center;
        font-family: "Poppins";
        font-weight: bold;
        padding-top: 50px;
        font-size: 35px;
        margin-bottom: 50px;
        color: white;
    }
    
    .card-title, .card-content, .card-title-small, .card-content-small{
        font-family: "Poppins";
        color: black;
    }
    
    .card-title{
        font-weight: bold;
        font-size: 23px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1cm;
    }
    
    .card-title-small{
        font-weight: bold;
        font-size: 23px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card-button{
        text-align: center;
        font-family: "Poppins";
        font-weight: bold;
        color: black;
        text-transform: none;
        font-size: 23px;
        background-color: transparent;
        border: none;
    }
    
    .card-button-small{
        text-align: center;
        font-family: "Poppins";
        font-weight: bold;
        color: black;
        text-transform: none;
        font-size: 20px;
        background-color: transparent;
        border: none;
    }
    
    .card-homepage{
        width: 290px;
        height: 350px;
        border-radius: 25px;
        margin-bottom: 50px;
    }
    
    .left-button-partners, .right-button-partners {
        background: transparent;
        border: none;
    }
    
    .left-button-lg, .right-button-lg {
        background: transparent;
        border: none;
        color: white;
    }
    
    .card-content{
        font-size: 20px;
        height: 128px;
    }
    
    .card-content-small{
        font-size: 20px;
        height: 128px;
    }
    
    .subtitulo-partners-section-4{
        font-size: 33px;
        margin: 0px 15px 30px 15px;
        font-weight: 350;
    }
    
    .partners-section-4{
        background-color: #725021;
        height: 664px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}