.homepage-container {
    body{
        line-height: 0.8cm;
    }
    
    .primary-section-1{
        background-color: #D8E1E0;
        height: 664px;
        display: flex;
        align-items: center;
    }
    
    .titulo-home{
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin: 0% 10%;
        font-size: 42px;
        max-width: 500px;
        text-align: justify;
    }
    
    .subtitulo-home{
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        font-weight: lighter;
        color: #777776;
        font-size: 23px;
        margin: 10px 0px 10px 0px;
    }
    
    .titulo-home-lg{
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 35px;
        color: black;
        text-shadow: 1px 1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255), 1px -1px 0 rgb(255, 255, 255), -1px 1px 0 rgb(255, 255, 255);
        font-weight: 700;
        margin: 20% 5%;
    }
    
    .subtitulo-home-lg{
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        max-width: 400px;
        color: black;
        text-shadow: 1px 1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255), 1px -1px 0 rgb(255, 255, 255), -1px 1px 0 rgb(255, 255, 255);
    }
    
    .send-now-button{
        background-color: black;
        color: white;
        border: 2px solid black;
        border-radius: 5px; /* Arredondar os cantos */
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        margin: 30px 5px 5px 5px;
        font-size: 17px;
        width: 100%;
        padding: 10px 50px 10px 50px;
        font-weight: lighter;
        cursor: pointer;
    }
    
    .tracking-button{
        background-color: whitee;
        color: black;
        border: 0.5px solid black;
        border-radius: 5px; /* Arredondar os cantos */
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        margin: 5px 5px 5px 5px;
        font-size: 17px;
        width: 100%;
        padding: 10px 50px 10px 50px;
        font-weight: lighter;
        cursor: pointer;
    }
    
    .secondary-section{
        background-color: #EFEEEC;
    }
    
    .titulo-secondary-section{
        text-align: center;
        font-family: "Poppins";
        font-weight: bold;
        padding-top: 50px;
        font-size: 35px;
        margin-bottom: 50px;
    }
    
    .card-title, .card-content, .card-title-small, .card-content-small{
        font-family: "Poppins";
        color: black;
    }
    
    .card-title{
        font-weight: bold;
        font-size: 28px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card-title-small{
        font-weight: bold;
        font-size: 23px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .card-button{
        text-align: center;
        font-family: "Poppins";
        font-weight: bold;
        color: black;
        text-transform: none;
        font-size: 23px;
        background-color: transparent;
        border: none;
    }
    
    .card-button-small{
        text-align: center;
        font-family: "Poppins";
        font-weight: bold;
        color: black;
        text-transform: none;
        font-size: 20px;
        background-color: transparent;
        border: none;
    }
    
    .card-homepage{
        width: 290px;
        height: 350px;
        border-radius: 25px;
        margin-bottom: 50px;
    }
    
    .left-button, .right-button {
        background: transparent;
        border: none;
    }
    
    .card-content{
        font-size: 23px;
        height: 128px;
    }
    
    .card-content-small{
        font-size: 20px;
        height: 128px;
    }
    
    .primary-section-3{
        background-color: #ffffff;
        height: 664px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .primary-section-3-lg{
        background-color: #F2F2F2;
        height: 380px;
        display: flex;
        align-items: center;
    }
    
    .primary-section-4{
        background-color: #EFEEEC;
        height: 664px;
        display: flex;
        align-items: center;
    }
    
    .primary-section-4-lg{
        background-color: #ffffff;
        height: 380px;
        display: flex;
        align-items: center;
    }
    
    .titulo-3-section{
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-left: 50px;
        font-size: 42px;
        text-align: center;
    }
    
    .subtitulo-3-section{
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        font-weight: lighter;
        max-width: 900px;
        color: #777776;
        text-align: justify;
        font-size: 23px;
        margin: 2% 0%;
    }
    
    .titulo-3-section-lg{
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-left: 12px;
        margin-right: 12px;
        font-size: 28px;
        color: #000000;
        text-shadow: 1px 1px 0 rgb(255, 255, 255), -1px -1px 0 rgb(255, 255, 255), 1px -1px 0 rgb(255, 255, 255), -1px 1px 0 rgb(255, 255, 255);
        margin-bottom: 0px;
    }
    
    .subtitulo-3-section-lg{
        font-family: 'Poppins', sans-serif;
        text-transform: none;
        font-weight: lighter;
        max-width: 900px;
        color: #777776;
        font-size: 22px;
        margin: 10px 0px 10px 0px;
    }
    
    h2{
        margin-bottom: 0px;
    }
}