.home-page {
    width: 100vw;
    background-color: var(--branco);
    margin: 0 auto;
    height: 100vh;
}

.motum-logo{
    width: 100px;
    height: 25px;
    cursor: pointer;
}

.navbar{
    background-color: white;
    position: sticky;
}

.nav-item{
    color: black;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    margin-left: 5px;
    background-color: white;
    border: none;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02857em;
    min-width: 64px;
}

.toolbar{
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 64px;
    position: relative;
    align-items: center;

}

.login-button{
    background-color: black;
    color: white;
    border: 2px solid black;
    border-radius: 5px; /* Arredondar os cantos */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    margin-left: 5px;
    padding: 10px;
    font-size: 16px;
}

.create-account-button{
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 5px; /* Arredondar os cantos */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    margin-left: 5px;
    padding: 10px;
    font-size: 16px;
}

.login-button-lg{
    background-color: black;
    color: white;
    border: 2px solid black;
    border-radius: 5px; /* Arredondar os cantos */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    width: 100%;
    margin-left: 5px;
    font-size: 16px;
}

.create-account-button-lg{
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 5px; /* Arredondar os cantos */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    width: 100%;
    margin-left: 5px;
    font-size: 16px;
}

.footer-header{
    background-color: transparent;
    border: none;
    font-size: 19px;
    text-align: left;
    font-weight: bolder;
    padding: 0px;
}

.footer-topic{
    background-color: transparent;
    border: none;
    font-size: 19px;
    text-align: left;
    margin-left: 15px;
}

.nav-item.selected {
    border-bottom: 2px solid #000; /* Exemplo de sublinhado */
    font-weight: bold; /* Opcional: destacar o texto */
}

@media (max-width: 1199px) {
    .nav-container {
        display: none; /* Esconde a navegação em telas pequenas */
    }
    .menu-button {
        background-color: transparent;
        color: inherit;
        border: none;
    }
}

@media (min-width: 1200px) {
    .menu-button {
        display: none; /* Esconde a navegação em telas pequenas */
    }
}

.mydelivery-section-1{
    background-color: #232323;
    height: 664px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
