.manage-wallet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .manage-wallet-header {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 95%;
        margin-bottom: 3%;

        .back-page {
            height: 30px;
            width: 30px;
            border: 1px solid#ccc;
            border-radius: 50%;
            color: #fff;
            transition: transform 0.3s ease;
            background-color: #fff;

            &:hover {
                transform: scale(1.1);
            }

            svg {
                color: #000;
            }
        }

        .manage-wallet-title {
            margin-left: 10px;
            font-size: 20px;
            font-weight: 500;
        }
    }

    .selected-wallet-container {
        width: 100%;
        height: 90%;
        gap: 20px;
        margin-top: 10px;
        display: inline-flex;
        justify-content: space-between;
        align-items: flex-start;

        .wallet-header-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 40%;

            .wallet-details-container {
                background-color: #000;
                color: #fff;
                border-radius: 10px;
                height: 50%;
                width: 100%;
                padding: 0px 30px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .wallet-title {
                    font-size: 20px;
                    margin-bottom: 10px;
                }

                .wallet-user-name {
                    line-height: 20px;
                    margin-bottom: 10px;

                    .user-title {
                        font-size: 14px;
                    }

                    .user-content {
                        font-size: 16px;
                    }
                }

                .wallet-balance {
                    line-height: 25px;
                    margin-bottom: 15px;

                    .amount-title {
                        font-size: 16px;
                    }

                    .amount-value {
                        font-size: 24px;
                        font-weight: 500;
                    }
                }

            }

            .wallet-actions {
                display: inline-flex;
                padding: 0px 5%;
                align-items: center;
                justify-content: center;
                gap: 30px;
                width: 100%;

                button {
                    border-radius: 10px;
                    height: 120px;
                    width: 120px;
                    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    cursor: pointer;
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                .box-danger {
                    background-color: #fff;
                    border: 2px solid red;
                    color: red;
                }
    
                .box-success {
                    background-color: #fff;
                    border: 2px solid green;
    
                    color: green;
                }
            }
        }


        .wallet-list-transactions {
            width: 60%;
            height: 100%;
            font-size: 12px;

            .loading-transactions {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 80%;
            }

            .transaction-type-credit,
            .transaction-value-credit {
                color: green;
                font-weight: 500;
            }

            .transaction-type-debit,
            .transaction-value-debit {
                color: red;
                font-weight: 500;
            }
        }
    }
}