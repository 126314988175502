@media (min-width: 1280px) {
    .register-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .register-form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-radius: 10px;
            height: 100%;
            width: 100%;
            padding: 0px;
        
            h1 {
                font-size: 20px;
                font-weight: 500;
                margin: 10px 0px;
            }
        
            .form-container {
                display: inline-flex;
                align-items: center;
                justify-content: left;
                width: 100%;
                height: 100%;

                .left-container{
                    background: linear-gradient(45deg, #252525, #6b6b6b, #252525);
                    width: 60%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .title-left-container{
                    font-size: 50px;
                    color: white;
                    margin: 50px 200px 20px 50px;
                }

                .subtitle-left-container{
                    font-size: 17px;
                    color: white;
                    margin: 50px 200px 5px 50px;

                    .icon-left-container-anotaai{
                        width: 200px;
                        margin-right: 50px;
                    }

                    .icon-left-container-ifood{
                        width: 120px;                    
                    }
                }

                
        
                .right-container {
                    height: 100%;
                    width: 40%;
                    margin: 0% 5%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 15px;
                    gap: 20px;
        
                    .form-duo-input {
                        display: inline-flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;
                    }

                    .form-label {
                        margin-top: 2%;
                        width: 50%;
                    }
        
                    .form-title {
                        font-size: 38px;
                        font-weight: 400;
                    }
        
                    .form-subtitle {
                        font-size: 18px;
                        margin: 10px 50px 10px 0px;
                        color: gray;
                        line-height: 20px;
                    }
        
                    .label-text {
                        font-size: 15px;
                        font-weight: 400;
                        margin-bottom: 5px;
                        font-weight: 500;
                    }
        
                    .form-control-solo {
                        border: 1px solid #ccc;
                        height: 30px;
                        width: 100%;
                        margin-bottom: 5px;
                        padding: 20px 10px;
                        border-radius: 5px;
                        font-size: 15px;
                        color: black;
                    }
                    
                    .form-control {
                        border: 1px solid #ccc;
                        width: 100%;
                        height: 30px;
                        margin-bottom: 5px;
                        padding: 20px 10px;
                        border-radius: 5px;
                        font-size: 15px;
                        color: black;
                    }

                    .form-control-select {
                        border: 1px solid #ccc;
                        height: 40px;
                        width: 100%;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        font-size: 15px;
                        color: black;
                    }

                    .id-ifood-input {
                        height: 100px;
                    }
        
                    .progress-button{
                        background-color: black;
                        color: white;
                        border: 2px solid black;
                        border-radius: 5px;
                        margin-top: 5%;
                        text-align: center;
                        text-decoration: none;
                        font-family: 'Poppins', sans-serif;
                        text-transform: none;
                        padding: 10px;
                        font-size: 16px;
                        align-items: center;
                        width: 100%;
                    }

                    .footer-form-label {
                        line-height: 20px;
                    }
                }
            }
        
            .submit-register-form {
                margin-top: 10px;
                background-color: #000;
                border: none;
                border-radius: 5px;
                color: #fff;
                font-weight: 500;
                padding: 10px 20px;
                width: 95%;
            }
        
            .submit-register-form:hover {
                background-color: #122b4a;
            }
        
        
        
        }
    }
}

@media (max-width: 1279px) {
    .register-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .register-form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-color: #fff;
            border-radius: 10px;
        
            h1 {
                font-size: 20px;
                font-weight: 500;
                margin: 10px 0px;
            }
        
            .form-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: left;

                .left-container{
                    background: linear-gradient(45deg, #252525, #6b6b6b, #252525);
                    display: flex;
                    padding: 5%;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                }

                .title-left-container{
                    font-size: 28px;
                    color: white;
                    margin: 2% 0;
                }

                .subtitle-left-container{
                    font-size: 17px;
                    color: white;
                    margin: 2% 0;

                    .icon-left-container-anotaai{
                        width: 20%;
                        margin-right: 50px;
                    }

                    .icon-left-container-ifood{
                        width: 15%;                    
                    }
                }

                
        
                .right-container {
                    padding: 5%;
                    width: 100%;
        
                    .form-title {
                        font-size: 28px;
                        font-weight: 400;
                    }
        
                    .form-subtitle {
                        font-size: 17px;
                        margin: 5% 0;
                        color: gray;
                    }
        
                    .label-text {
                        font-size: 15px;
                        font-weight: 400;
                        margin-bottom: 5px;
                        font-weight: 500;
                    }
        
                    .form-control-solo {
                        border: 1px solid #ccc;
                        height: 30px;
                        width: 100%;
                        margin-bottom: 5px;
                        padding: 20px 10px;
                        border-radius: 5px;
                        font-size: 15px;
                        color: black;
                    }
                    
                    .form-control {
                        border: 1px solid #ccc;
                        height: 30px;
                        width: 100%;
                        margin-bottom: 5px;
                        padding: 20px 10px;
                        border-radius: 5px;
                        font-size: 15px;
                        color: black;
                    }

                    .form-control-select {
                        border: 1px solid #ccc;
                        height: 40px;
                        width: 100%;
                        margin-bottom: 5px;
                        border-radius: 5px;
                        font-size: 15px;
                        color: black;
                    }

                    .id-ifood-input {
                        height: 100px;
                    }
        
                    .progress-button{
                        background-color: black;
                        color: white;
                        border: 2px solid black;
                        border-radius: 5px;
                        margin-top: 5%;
                        text-align: center;
                        text-decoration: none;
                        font-family: 'Poppins', sans-serif;
                        text-transform: none;
                        padding: 10px;
                        font-size: 16px;
                        align-items: center;
                        width: 100%;
                    }

                    .footer-form-label {
                        margin-top: 5%;
                        line-height: 20px;
                    }
                }
            }
        
            .submit-register-form {
                margin-top: 10px;
                background-color: #000;
                border: none;
                border-radius: 5px;
                color: #fff;
                font-weight: 500;
                padding: 10px 20px;
                width: 95%;
            }
        
            .submit-register-form:hover {
                background-color: #122b4a;
            }
        
        
        
        }
    }
}

