.services-container {
    @media (min-width: 1200px) {
        .section1-services {
        background-color: #232323;
        height: 600px;
        display: grid;
        grid-template-columns: 7fr 5fr;
        }
    
        .sub-section1-services {
            background-color: #323232;
            width: 100%;
            height: 100%;
            border-radius: 0% 60% 60% 0%;
            display: flex;
            justify-content: center; /* Centraliza horizontalmente */
            align-items: center; /* Centraliza verticalmente */
        }
    
        .table-section1 {
            max-width: 100%; /* Limita a largura da imagem para não ultrapassar o contêiner */
            height: auto; /* Mantém a proporção da imagem */
            display: block; /* Garante que a imagem seja tratada como um bloco para centralizar */
            margin: 0 auto; /* Centraliza horizontalmente */
        }
    
        .sub2-section1-services{
            height: 100%;
            display: flex;
            align-items: center;
        }
    
        .title-section1-services{
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
            text-align: center;
            color: white;
            font-size: 42px;
        }
    
        .p1-section1-services{
            font-family: 'Poppins', sans-serif;
            font-weight: normal;
            text-align: center;
            color: white;
            font-size: 28px;
            margin: 15px 0px 15px 0px;
        }
    
        .subp-section1-services{
            font-family: 'Poppins', sans-serif;
            font-weight: normal;
            text-align: center;
            color: white;
            font-size: 23px;
            padding: 0px 50px 0px 50px;
        }
        .section2-services {
            background-color: white;
            height: 600px;
            display: grid;
            grid-template-columns: 7fr 5fr;
            }
        
            .sub-section2-services {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center; /* Centraliza horizontalmente */
                align-items: center; /* Centraliza verticalmente */
                background-size: cover;
            }
        
            .sub2-section2-services{
                height: 100%;
                display: flex;
                align-items: center;
            }
        
            .title-section2-services{
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                text-align: center;
                color: black;
                font-size: 42px;
            }
        
            .p1-section2-services{
                font-family: 'Poppins', sans-serif;
                font-weight: normal;
                text-align: center;
                color: black;
                font-size: 28px;
                margin: 15px 0px 15px 0px;
            }
        
            .subp-section2-services{
                font-family: 'Poppins', sans-serif;
                font-weight: normal;
                text-align: center;
                color: black;
                font-size: 23px;
                padding: 0px 50px 0px 50px;
            }
    
            .image-section2 {
                width: 100%;  /* Garante que a imagem ocupe toda a largura do contêiner */
                max-height: 600px; /* Garante que a imagem ocupe toda a altura do contêiner */
                object-fit:cover;  /* Faz a imagem cobrir completamente o contêiner */
            }
    
            .sub-section3-services {
                background-color: #323232;
                width: 100%;
                height: 100%;
                border-radius: 0% 60% 60% 0%;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Centraliza horizontalmente */
                align-items: center; /* Centraliza verticalmente */
            }
    
            .subp-section3-services{
                font-family: 'Poppins', sans-serif;
                font-weight: normal;
                text-align: center;
                color: white;
                font-size: 23px;
                margin: 0% 20%;
            }
    
            .subtitle-left-container{
                font-size: 17px;
                color: white;
                display: flex;
                justify-content: center;
                width: 100%;
                gap: 50px;
    
                .icon-left-container-anotaai{
                    margin: 1% 0%;
                    width: 15%;
                    height: 78%;
                    max-width: 150px;
                    max-height: 60px;
                }
    
                .icon-left-container-ifood{
                    margin: 1.8% 0% 0% 0%;
                    width: 10%;
                    height: 68%;     
                    max-width: 150px;
                    max-height: 60px;               
                }
            }
    
            .section4-services{
                background-color: white;
                display: flex;
                justify-content: center;
                padding: 20px 0 0 20px;
            }
    
            .title-section4-services{
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                text-align: center;
                color: black;
                font-size: 50px;
                margin: 20px 0px 15px 0px;
            }
    
            .p1-section4-services{
                font-family: 'Poppins', sans-serif;
                font-weight: none;
                text-align: center;
                color: black;
                font-size: 32px;
                margin: 15px 0px 15px 0px;
                padding: 20px 0 80px 0;
            }
    
            .image-section4{
                width: 500px;
            }
    
            .subp-section4-services{
                font-family: 'Poppins', sans-serif;
                font-weight: none;
                text-align: center;
                color: black;
                font-size: 23px;
                padding: 20px 0 80px 0;
            }
    }
    
    @media (max-width: 1199px) {
        .section1-services {
        background-color: #323232;
        padding-bottom: 80px;
        }
    
        .sub-section1-services {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: center; /* Centraliza horizontalmente */
            align-items: center; /* Centraliza verticalmente */
            margin-bottom: 70px;
        }
    
        .table-section1 {
            max-width: 70%; /* Limita a largura da imagem para não ultrapassar o contêiner */
            height: auto; /* Mantém a proporção da imagem */
            display: block; /* Garante que a imagem seja tratada como um bloco para centralizar */
            margin: 0 auto; /* Centraliza horizontalmente */
            padding-top: 50px;
        }
    
        .sub2-section1-services{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        .title-section1-services{
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
            text-align: center;
            color: white;
            font-size: 30px;
        }
    
        .p1-section1-services{
            font-family: 'Poppins', sans-serif;
            font-weight: normal;
            text-align: center;
            color: white;
            font-size: 23px;
            margin: 15px 0px 15px 0px;
        }
    
        .subp-section1-services{
            font-family: 'Poppins', sans-serif;
            font-weight: normal;
            text-align: center;
            color: white;
            font-size: 20px;
            margin: 10px 10px 0px 10px;
        }
        .section2-services {
            background-color: white;
            display: flex;
            flex-direction: column-reverse;
            padding-bottom: 80px;
            }
        
            .sub-section2-services {
                width: 100%;
                height: 50%;
                display: flex;
                justify-content: center; /* Centraliza horizontalmente */
                align-items: center; /* Centraliza verticalmente */
            }
        
            .image-section2 {
                width: 100%;  /* Garante que a imagem ocupe toda a largura do contêiner */
                max-height: 380px; /* Garante que a imagem ocupe toda a altura do contêiner */
                object-fit: cover;  /* Faz a imagem cobrir completamente o contêiner */
                background-position: initial;
            }
        
            .sub2-section2-services{
                padding-top: 50px;
                display: flex;
                align-items: center;
            }
        
            .title-section2-services{
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                text-align: center;
                color: black;
                font-size: 35px;
            }
        
            .p1-section2-services{
                font-family: 'Poppins', sans-serif;
                font-weight: normal;
                text-align: center;
                color: black;
                font-size: 23px;
                margin: 15px 0px 15px 0px;
            }
        
            .subp-section2-services{
                font-family: 'Poppins', sans-serif;
                font-weight: normal;
                text-align: center;
                color: black;
                font-size: 20px;
                padding: 0px 50px 0px 50px;
            }
            
            .sub-section3-services {
                background-color: #323232;
                width: 100%;
                height: 100%;
                border-radius: 0% 60% 60% 0%;
                display: flex;
                flex-direction: column;
                justify-content: center; /* Centraliza horizontalmente */
                align-items: center; /* Centraliza verticalmente */
            }
    
            .deliveryboy-section3{
                display: flex;
                align-items: center;
            }
    
            .ifood-section3{
                width: 50px; /* Define a margem direita como automática */
                margin-right: 10px;
            }
    
            .subp-section3-services{
                font-family: 'Poppins', sans-serif;
                font-weight: normal;
                text-align: center;
                color: white;
                font-size: 20px;
                margin: 10px 10px 30px 10px;
            }
    
            .subtitle-left-container{
                font-size: 17px;
                color: white;
                display: flex;
                justify-content: center;
                width: 100%;
                gap: 50px;
    
                .icon-left-container-anotaai{
                    margin: 1% 0%;
                    width: 15%;
                    height: 78%;
                    max-width: 150px;
                    max-height: 60px;
                    min-width: 120px;
                    min-height: 50px;
                }
    
                .icon-left-container-ifood{
                    margin: 1.8% 0% 0% 0%;
                    width: 10%;
                    height: 68%;     
                    max-width: 150px;
                    max-height: 60px;   
                    min-width: 120px;
                    min-height: 50px;            
                }
            }
    
            .section4-services{
                background-color: white;
                display: flex;
                justify-content: center;
                padding: 20px 0 0 0px;
            }
    
            .title-section4-services{
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                text-align: center;
                color: black;
                font-size: 23px;
                margin: 2%;
            }
    
            .p1-section4-services{
                font-family: 'Poppins', sans-serif;
                font-weight: none;
                text-align: center;
                color: black;
                font-size: 17px;
                margin: 15px 0px 15px 0px;
                padding: 20px 0 20px 0;
            }
    
            .image-section4{
                width: 80%;
            }
    
            .subp-section4-services{
                font-family: 'Poppins', sans-serif;
                font-weight: none;
                text-align: center;
                color: black;
                font-size: 12px;
                padding: 20px 0 80px 0;
            }
    }
}