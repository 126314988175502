.pending-orders-list {
    width: 100%;
    height: 80%;
}

.pending-orders-list-loading {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pending-users-table {
    font-size: 12px;
    
    .lg-column {
        width: 15%;
    }

    .td-actions-buttons {
        display: inline-flex;
        gap: 10px;
    }
    
    .aprove-user-btn {
        background-color: green;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        color: #fff;
        width: 110px;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
    }

    .disaprove-user-btn {
        background-color: red;
        width: 110px;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        color: #fff;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: scale(1.05);
        }
    }
}