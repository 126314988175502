.conclusion {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #000;
    color: #fff;
    font-size: 16px;

    @media screen and (min-width: 900px) {
        font-size: 22px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 25px;
    }

    &__container {
        width: 20rem;
        height: 20rem;

        @media screen and (min-width: 900px) {
            width: 22rem;
            height: 22rem;
        }

        @media screen and (min-width: 1200px) {
            width: 25rem;
            height: 25rem;
        }
    }

    &__logo {
        width: 12rem;
    }

    &__title {
        text-align: center;
        font-family: "Nothing You Could Do", cursive;
        font-size: 1.8em;
        margin-bottom: 1.5rem;
    }

    &__subtitle {
        font-family: var(--main-font);
        text-align: center;
        padding: 0 10%;
        font-size: 1em;
    }
}
