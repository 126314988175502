.data-privacy-policy {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5%;
  overflow-y: auto;

  .policy-title, .section-title {
    margin-bottom: 30px;
  }
  .policy-title {
    font-size: 1.5em;
  }
  .section-title {
    font-size: 1.25em;
  }
  
  .policy-text {
    padding-top: 5px;
  }
  
  .policy-intro, .policy-sections, .policy-details {
    margin: 25px auto;
    padding: 15px;
    max-width: 1000px;
    //border-radius: 8px;
    //background-color: #fff;
    //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .data-list {
    list-style-type: disc;
    padding-left: 20px;
    margin: 5px;
  }
  .section-list {
    list-style-type: decimal;
    padding-left: 20px;
    margin: 5px;
  }
}
