@media (min-width: 1200px) {
    .main-login-container {
        height: 100%;
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-family: var(--main-font);

        & .left-login-image-container {
            background-color: #fff;
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            & .login-image-info {
                height: 30%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                padding: 5%;

                & h1 {
                    color: #000;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 40px;
                }

                & p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 40px;
                    color: #777776;
                }
            }

            .login-image {
                display: flex;
                width: 100%;
                height: 70%;
                justify-content: flex-end;

                & img {
                    height: 100%;
                    width: 100%;
                }
            }

        }

        & .main-login-form {
            background-color: #fff;
            border-left: 1px solid rgba($color: #ccc, $alpha: 0.5);
            height: 100%;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            & form,
            .form-forgot-password {
                height: 80%;
                width: 80%;
                display: flex;
                padding: 5%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                & .form-login-logo {
                    height: 8%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5%;

                    & img {
                        height: 100%;
                    }
                }

                & .form-login-title {
                    height: 15%;
                    width: 100%;
                    margin-bottom: 2%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    & h1 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #3E3E3E;
                    }
                }

                & .login-form-control {
                    width: 100%;
                    height: 15%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2%;

                    & label {
                        height: 30%;
                        color: #3E3E3E;
                        font-size: 14px;
                    }

                    .password-input {
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 0.5px solid rgba(62, 62, 62, 0.3);
                        height: 70%;
                        width: 100%;
                        border-radius: 5px;
                        background: transparent;

                        input {
                            height: 100%;
                            width: 90%;
                            border: none;
                        }

                        .password-visible {
                            height: 100%;
                            width: 10%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            svg {
                                height: 20px;
                                color: #1a1a1a;
                            }
                        }
                    }

                    input {
                        border: 0.5px solid rgba(62, 62, 62, 0.3);
                        height: 70%;
                        width: 100%;
                        border-radius: 5px;
                        background: transparent;
                        padding: 2%;
                    }

                }

                & .login-form-check {
                    width: 100%;
                    height: 10%;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 2% 0;

                    .logged-check {
                        width: 50%;
                        height: 100%;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;

                        input {
                            height: 80%;
                            width: 1.5vw;
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        span {
                            font-weight: 400;
                            font-size: 80%;
                            color: #3E3E3E;
                        }
                    }

                    .forgot-password {

                        span {
                            cursor: pointer;
                            font-weight: 400;
                            font-size: 14px;
                            color: #3E3E3E;
                        }
                    }
                }

                & .login-submit-btn {
                    background: #000;
                    border: none;
                    border-radius: 5px;
                    width: 100%;
                    height: 10%;
                    margin: 2% 0;
                    color: #fff;
                    font-size: 100%;
                    font-weight: 500;
                }

                & .main-footer-login {
                    width: 100%;
                    height: 10%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    & .footer-login-text {
                        font-size: 14px;
                        margin-right: 10px;
                    }

                    & .footer-login-link a {
                        text-decoration: none;
                        color: #000;
                    }
                }

                .code-label {
                    font-size: 14px;
                    font-weight: 300;
                    width: 100%;
                    text-align: left;
                    margin: 20px 0;
                }

                .code-inputs {
                    display: inline-flex;
                    justify-content: space-between;
                    gap: 10px;
                    margin-bottom: 20px;
                    width: 100%;
                }

                .code-inputs input {
                    width: 100px;
                    height: 100px;
                    font-size: 40px;
                    text-align: center;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                }

                .code-actions {
                    display: inline-flex;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                    .code-btn-send {
                        width: 50%;
                        background: #000;
                        border: none;
                        border-radius: 5px;
                        width: 100%;
                        height: 100%;
                        margin: 2% 0;
                        color: #fff;
                        font-size: 100%;
                        font-weight: 500;
                    }

                    .code-btn-resend {
                        width: 50%;
                        background: #EEEEEE;
                        border: none;
                        border-radius: 5px;
                        width: 100%;
                        height: 100%;
                        margin: 2% 0;
                        color: #000;
                        font-size: 100%;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .main-login-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        font-family: var(--main-font);

        & .left-login-image-container {
            background-color: #fff;
            height: 50%;
            width: 100%;
            display: flex;
            align-items: center;

            & .login-image-info {
                height: 100%;
                width: 100%;
                padding: 5%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                & h1 {
                    color: #000;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 40px;
                }

                & p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 40px;
                    color: #777776;
                }
            }

            .login-image {

                width: 100%;
                height: 100%;

                & img {
                    height: 100%;
                    width: 100%;
                }
            }

        }

        & .main-login-form {
            background-color: #fff;
            border-left: 1px solid rgba($color: #ccc, $alpha: 0.5);


            & form {
                height: 80%;
                width: 100%;
                display: flex;
                padding: 5%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                & .form-login-logo {
                    height: 8%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5%;

                    & img {
                        height: 100%;
                    }
                }

                & .form-login-title {
                    height: 15%;
                    width: 100%;
                    margin-bottom: 2%;


                    & h1 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #3E3E3E;
                    }
                }

                & .login-form-control {
                    width: 100%;
                    height: 15%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2%;

                    & label {
                        height: 30%;
                        color: #3E3E3E;
                        font-size: 14px;
                    }

                    input {
                        border: 0.5px solid rgba(62, 62, 62, 0.3);
                        height: 70%;
                        width: 100%;
                        border-radius: 5px;
                        background: transparent;
                        padding: 2%;
                    }

                }

                & .login-form-check {
                    width: 100%;
                    height: 10%;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 2% 0;

                    .logged-check {
                        width: 50%;
                        height: 100%;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;

                        input {
                            height: 80%;
                            width: 1.5vw;
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        span {
                            font-weight: 400;
                            font-size: 80%;
                            color: #3E3E3E;
                        }
                    }

                    .select-model {
                        width: 50%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;

                        label {
                            font-size: 8px;
                            margin-bottom: 5px;
                        }

                        .login-select {
                            height: 80%;
                            width: 70%;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    }
                }

                & .login-submit-btn {
                    background: #000;
                    border: none;
                    border-radius: 5px;
                    width: 100%;
                    height: 10%;
                    margin: 2% 0;
                    color: #fff;
                    font-size: 100%;
                    font-weight: 500;
                }

                & .main-footer-login {
                    width: 100%;
                    height: 10%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    & .footer-login-text {
                        font-size: 14px;
                        margin-right: 10px;
                    }

                    & .footer-login-link a {
                        text-decoration: none;
                        color: #000;
                    }
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .main-login-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        font-family: var(--main-font);

        & .left-login-image-container {
            background-color: #fff;
            height: 50%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & .login-image-info {
                height: 50%;
                width: 100%;
                padding: 5%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                & h1 {
                    color: #000;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 40px;
                }

                & p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 40px;
                    color: #777776;
                }
            }

            .login-image {

                width: 100%;
                height: 100%;

                & img {
                    height: 100%;
                    width: 100%;
                }
            }

        }

        & .main-login-form {
            background-color: #fff;
            border-left: 1px solid rgba($color: #ccc, $alpha: 0.5);


            & form {
                height: 80%;
                width: 100%;
                display: flex;
                padding: 5%;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                & .form-login-logo {
                    height: 8%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5%;

                    & img {
                        height: 100%;
                    }
                }

                & .form-login-title {
                    height: 15%;
                    width: 100%;
                    margin-bottom: 2%;


                    & h1 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #3E3E3E;
                    }
                }

                & .login-form-control {
                    width: 100%;
                    height: 15%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 2%;

                    & label {
                        height: 30%;
                        color: #3E3E3E;
                        font-size: 14px;
                    }

                    input {
                        border: 0.5px solid rgba(62, 62, 62, 0.3);
                        height: 70%;
                        width: 100%;
                        border-radius: 5px;
                        background: transparent;
                        padding: 2%;
                    }

                }

                & .login-form-check {
                    width: 100%;
                    height: 10%;
                    display: inline-flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 2% 0;

                    .logged-check {
                        width: 50%;
                        height: 100%;
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;

                        input {
                            height: 80%;
                            width: 1.5vw;
                            margin-right: 10px;
                            cursor: pointer;
                        }

                        span {
                            font-weight: 400;
                            font-size: 80%;
                            color: #3E3E3E;
                        }
                    }

                    .select-model {
                        width: 50%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;

                        label {
                            font-size: 8px;
                            margin-bottom: 5px;
                        }

                        .login-select {
                            height: 80%;
                            width: 70%;
                            border-radius: 5px;
                            cursor: pointer;
                        }
                    }
                }

                & .login-submit-btn {
                    background: #000;
                    border: none;
                    border-radius: 5px;
                    width: 100%;
                    height: 10%;
                    margin: 2% 0;
                    color: #fff;
                    font-size: 100%;
                    font-weight: 500;
                }

                & .main-footer-login {
                    width: 100%;
                    height: 10%;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    & .footer-login-text {
                        font-size: 14px;
                        margin-right: 10px;
                    }

                    & .footer-login-link a {
                        text-decoration: none;
                        color: #000;
                    }
                }
            }
        }
    }
}